import React from "react";
import "./ProtectionPolicy.scss";
import {Link} from "react-router-dom";

const ProtectionPolicy = () => (
  <section className="ProtectionPolicy">
    <h2>POLÍTICA DE PROTEÇÃO À DADOS PESSOAIS</h2>
    <div>
      <div>
        <h3>EMPRESA</h3>
        <p>
          Esta é a política de proteção à dados
          Pessoais da <span>MASTERTECH SERVIÇOS DE TREINAMENTO PROFISSIONAL LTDA.</span>, nome fantasia <span>Mastertech
          </span>, empresa com sede na cidade de São Paulo, SP, Av. Brig. Luis Antonio, 2696,
          Conjunto 17, inscrita no CNPJ/ MF sob o número 24.964.042/0001-00, doravante denominada como EMPRESA
        </p>
      </div>
      <div>
        <h3>OBJETIVO DESTA POLÍTICA</h3>
        <p>
          Assegurar a proteção aos direitos dos titulares de todos os dados pessoais coletados, armazenados e
          processados pela
          EMPRESA (os DADOS) por meio do aprimoramento tecnológico, treinamentos e utilização das melhores práticas do
          mercado, conforme definições adiante detalhadas e definições estabelecidas na Lei
          Geral de Proteção de Dados (Lei 13.709/2018), disponível em <Link
          onClick={(event) => {
            event.preventDefault();
            window.open(("http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"))
          }}
          to="route"
          target="_blank">http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm</Link>, acesso em: 18
          de janeiro de 2021
        </p>
      </div>
      <div>
        <h3>DIRETRIZES</h3>
        <h4>Coleta de Dados</h4>
        <p>
          Os dados pessoais armazenados na EMPRESA são coletados
          mediante: <span>a)</span> Site; <span>b)</span> Oriundo de clientes.
        </p>
        <h4>Armazenamento</h4>
        <p>
          Todos os dados serão armazenados no sistema Google Drive ou
          similar, com exceção de dados vindos de outros sistemas e alunos
          que compram os cursos pelo site, os quais são armazenados em
          bancos de dados na RDS na AWS (banco de dados da Amazon).
        </p>
      </div>
      <div>
        <h3>ACESSO AOS DADOS</h3>
        <p>
          O sistema interno da empresa foi preparado para que somente
          pessoas autorizadas e que necessitem acessar determinados dados
          para a realização de suas tarefas tenham acesso a estes, da seguinte
          forma:
        </p>
        <h4>Dados dos colaboradores</h4>
        <p>O acesso a estes dados está limitado aos sócios da EMPRESA e
          membros do RH.
        </p>
        <h4>Dados sobre ex-funcionários</h4>
        <p>O acesso a estes dados está limitado aos sócios da EMPRESA e
          gerentes administrativos.</p>
        <h4>Alunos e ex-alunos de cursos</h4>
        <p>O acesso a estes dados está limitado às pessoas que necessitem a
          informação acerca do nome de alunos e ex-alunos dos cursos.</p>
        <h4>Funções e Atividades</h4>
        <p>
          <span>> Sócios: </span> Preparação das aulas, conteúdos, toda aprovação financeira, gestão
          de pessoas, planejamento estratégico, garantia de qualidade,
          criação de proposta comercial, criação de produtos.
        </p>
        <p>
          <span>> Gerente Comercial: </span> Co-responsável pela execução da estratégia comercial e da nutrição
          do relacionamento com clientes, do pré ao pós-venda.
        </p>
        <p>
          <span>> Assistente Corporativo: </span> Atividades de suporte técnico, pedagógico e operacional relativos a
          dinâmica de prestação de serviços de aulas e extensivos ao
          processos de comunicação digital e atendimento ao cliente.
        </p>
        <p>
          <span>> Desenvolvedor Pleno I: </span> Desenvolvedor de Operação (DevOps)
        </p>
        <p>
          <span>> Professores e Monitores: </span> Ministram as aulas e auxiliam os alunos.
        </p>
        <p>
          Todos os colaboradores, assim entendidos como as pessoas físicas
          contratadas pela Empresa por qualquer regime, que tiverem acesso
          aos dados em posse da Empresa assinarão termo de
          confidencialidade comprometendo-se com a segurança e correta
          utilização destes (dados).
          Os colaboradores terão acesso aos dados para a prestação dos
          serviços indicados na contratação e somente poderão utilizar os
          dados entregues pela EMPRESA para o fim exclusivo estabelecido
          no contrato ou indicado quando da entrega destes dados.
        </p>
      </div>
      <div>
        <h3>PROCESSAMENTO DE DADOS</h3>
        <p>
          Os dados pessoais armazenados na empresa serão utilizados
          somente para os fins a que se propõem ou conforme autorização
          expressa de seus titulares.
          Não são realizadas ações de marketing com os dados em posse da
          empresa, exceto com aqueles coletados com esta finalidade
          específica, devidamente autorizado por seus titulares.
        </p>
        <p>
          <span>> Encarregado: </span> A EMPRESA definiu como seu encarregado o funcionário <span>Eric Bueno
          Gauch</span>.
          <p>Contato: <a href="mailto:egauch@mastertech.com.br">egauch@mastertech.com.br</a></p>
        </p>
        <p>
          <span>> Funções do Encarregado: </span> O encarregado será o canal de comunicação entre a EMPRESA, os
          titulares dos dados e a Autoridade Nacional de Proteção de Dados
          (ANPD).
        </p>
        <h4>Suas funções consistirão em:</h4>
        <p>
          <span>I.</span> aceitar reclamações e comunicações dos titulares, prestar
          esclarecimentos e adotar providências;
        </p>
        <p>
          <span>II.</span> receber comunicações da autoridade nacional e adotar
          providências;
        </p>
        <p>
          <span>III.</span> orientar os funcionários e os contratados da entidade a
          respeito das práticas a serem tomadas em relação à proteção de
          dados pessoais;
        </p>
        <p>
          <span>IV.</span> executar as demais atribuições determinadas pelo
          controlador ou estabelecidas em normas complementares.
        </p>
      </div>
      <div>
        <h3>DIREITOS DOS TITULARES</h3>
        <h4>Portal do Titular</h4>
        <p>
          O titular dos DADOS tem, dentre outros, direito a obter do
          controlador (a EMPRESA), em relação aos dados do titular por ela
          tratados, a qualquer momento e mediante requisição:
        </p>
        <p>
          <span>I.</span> confirmação da existência de tratamento;
        </p>
        <p>
          <span>II.</span> acesso aos dados;
        </p>
        <p>
          <span>III.</span> correção de dados incompletos, inexatos ou desatualizados;
        </p>
        <p>
          <span>IV.</span> anonimização, bloqueio ou eliminação de dados
          desnecessários, excessivos ou tratados em desconformidade
          com o disposto nesta Lei;
        </p>
        <p>
          <span>V.</span> portabilidade dos dados a outro fornecedor de serviço ou
          produto, mediante requisição expressa, de acordo com a
          regulamentação da autoridade nacional, observados os
          segredos comercial e industrial;
        </p>
        <p>
          <span>VI.</span> eliminação dos dados pessoais tratados com o consenti-
          mento do titular, exceto nas hipóteses previstas no art. 16 a Lei
          Geral de Proteção de Dados;
        </p>
        <p>
          <span>VI.</span> eliminação dos dados pessoais tratados com o consenti-
          mento do titular, exceto nas hipóteses previstas no art. 16 a Lei
          Geral de Proteção de Dados;
        </p>
        <p>
          <span>VII.</span> informação das entidades públicas e privadas com as quais
          o controlador realizou uso compartilhado de dados;
        </p>
        <p>
          <span>VIII.</span> informação sobre a possibilidade de não fornecer
          consentimento e sobre as consequências da negativa;
        </p>
        <p>
          <span>IX.</span> revogação do consentimento;
        </p>
        <p>
          ㅤㅤ1º O titular dos dados pessoais tem o direito de peticionar em
          relação aos seus dados contra o controlador perante a
          autoridade nacional.
        </p>
        <p>
          ㅤㅤ 2º O titular pode opor-se a tratamento realizado com
          fundamento em uma das hipóteses de dispensa de
          consentimento, em caso de descumprimento ao disposto na Lei
          Geral de Proteção de Dados.
        </p>
        <p>
          ㅤㅤ3º Os direitos aqui previstos serão exercidos mediante
          requerimento expresso do titular ou de representante
          legalmente constituído, a agente de tratamento.
        </p>
        <p>
          ㅤㅤ4º Em caso de impossibilidade de adoção imediata da
          providência descrita acima, a EMPRESA enviará ao titular
          resposta em que poderá:
        </p>
        <p>
          <span>I.</span> comunicar que não é agente de tratamento dos dados e
          indicar, sempre que possível, o agente; ou
        </p>
        <p>
          <span>II.</span> indicar as razões de fato ou de direito que impedem a adoção
          imediata da providência.
        </p>
        <p>
          ㅤㅤ5º O requerimento será atendido sem custos para o titular,
          nos prazos e nos termos previstos em regulamento.
        </p>
        <p>
          ㅤㅤ6º O responsável deverá informar, de maneira imediata, aos
          agentes de tratamento com os quais tenha realizado uso compartilhado de dados a correção, a eliminação, a
          anonimização ou o bloqueio dos dados, para que repitam idêntico procedimento, exceto nos casos em que esta
          comunicação seja comprovadamente impossível ou implique esforço desproporcional.
        </p>
        <p>
          ㅤㅤ7º A portabilidade dos dados pessoais não inclui dados que já
          tenham sido anonimizados pelo controlador.
        </p>
        <p>
          ㅤㅤ8º O direito a que se refere o § 1o deste artigo também poderá
          ser exercido perante os organismos de defesa do consumidor.
        </p>
        <p>
          Para assegurar o cumprimento dos direitos do titular, a EMPRESA
          disponibiliza em seu site <Link to={"/portaldotitular"}>(www.mastertech.com.br/portaldotitular)</Link> ambiente
          no qual os titulares de dados pessoais podem questionar
          acerca da forma e duração do armazenamento, bem como solicitar a
          correção ou exclusão de seus dados do banco de dados da EMPRESA.
        </p>
      </div>
      <div>
        <h3>MÉTODO DE CONTROLE</h3>
        <p>O cumprimento desta política estará a cargo do Comitê de LGPD da
          EMPRESA.</p>
      </div>
      <div>
        <h3>ATUALIZAÇÕES E CORREÇÕES</h3>
        <p>Essa política será periodicamente revisada e atualizada, a fim que
          eventuais deficiências identificadas sejam pronta e integralmente
          corrigidas, de forma a garantir sua efetividade.</p>
        <h3>DISPOSIÇÕES FINAIS</h3>
        <p>Todos os colaboradores e prestadores de serviço da EMPRESA
          devem respeitar e cumprir plena e integralmente esta POLÍTICA,
          sob pena de advertência, suspensão, rescisão imediata de
          contratos, bem como outras ações disciplinares se adequadas, sem
          prejuízo do dever de reparação dos danos causados em decorrência
          de eventual descumprimento.</p>
      </div>
    </div>
  </section>
);

export default ProtectionPolicy;
