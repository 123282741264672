import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../../components/ui/ContactForm/ContactForm';
import ContactFormMobile from '../../components/ui/ContactFormMobile/ContactFormMobile';
import { isMobile } from '../../utils/responsiveness';
import './Contact.scss';

const Contact = () => {
  const [loading, setLoading] = useState(false);

  return (
    <main className="Contact">
      <h2>FALE COM A GENTE</h2>
      <p className='description'>Para dúvidas, necessidades, questionamentos ou para simplesmente tomar um café com a gente, deixe seus dados abaixo que a pessoa certa entrará em contato nas próximas horas ;)</p>
      {isMobile() ? <ContactFormMobile /> : <ContactForm inverted setLoading={setLoading} />}
      {!isMobile() && <article>
        <p>
          2022 Mastertech
        </p>
        <Link to='/politica-de-privacidade'>
          Políticas de privacidade
        </Link>
        <Link to='/portaldotitular'>
          Portal do Titular
        </Link>
        <a href='https://materiais.mastertech.com.br/mdaily-20202' target="_blank">
          Assine o M/Daily
        </a>
      </article>}
      {!isMobile() && (
        <>
          <h2>CONTATOS</h2>
          <address>
            <a href='tel:(11) 91952-2455'>(11) 91952-2455</a>
            <a href='mailto: oi@mastertech.com.br'>oi@mastertech.com.br</a>
            <p>Av. Brigadeiro Luis Antônio, 2696</p>
            <p>Sala 17 - CEP: 01402-000 </p>
            <p>Bela Vista - São Paulo - SP</p>
          </address>
        </>)}
      {!isMobile() && <div className='social-media'>
        <a href="https://www.instagram.com/mastertech.tech/" rel="noopener noreferrer" target="_blank">
          <img src={require("../../assets/images/instagram.png")} alt="instagram logo" />
        </a>
        <a href="https://www.linkedin.com/school/mastertech.tech/" rel="noopener noreferrer" target="_blank">
          <img src={require("../../assets/images/linkedin.png")} alt="linkedin logo" />
        </a>
      </div>}
    </main>
  )
}

export default Contact;
