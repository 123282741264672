export const headers = {
  "Content-Type": "application/json",
  Authorization: ""
};

export const processResponse = response => {
  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const urlFilters = (url, filters) => {
  if (filters === undefined) {
    return url;
  }

  const filtersKeys = Object.keys(filters);

  filtersKeys.forEach(key => {
    const keyValue = filters[key];
    if (keyValue !== '') {
      const symbol = url.includes('?') ? '&' : '?';
      if (Array.isArray(keyValue)) {
        for(let value of keyValue) {
          url += `${symbol}${key}=${value}`
        }
      }else {
        url += `${symbol}${key}=${keyValue}`
      }
    }
  });

  return url;
}

export const baseEndpoint = process.env.REACT_APP_LEAD_COLLECTOR_API;
export const baseSiteEndPoint = "https://api.mastertech.com.br";
export const msEndpoint = process.env.REACT_APP_ALEXANDRIA_API;
export const shoppingCardPK = process.env.REACT_APP_PAGSEGURO_PK;
