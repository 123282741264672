import { createContext, useContext, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { msEndpoint } from "../api/common";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cart, setCart] = useState(() => {
    const storagedCart = localStorage.getItem('@Mastertech:cart')

    if (storagedCart) {
      return JSON.parse(storagedCart);
    }

    return [];
  });

  const addProduct = async (productId, amount) => {
    try {
      const existsInCard = cart.find(product => product.id === productId)

      if (existsInCard) {
        const productIncrement = {
          productId: existsInCard.id,
          amount: amount > 1 ? amount : existsInCard.amount + 1
        }
        updateProductAmount(productIncrement)

      } else {
        const response = await fetch(`${msEndpoint}/shopping/product/${productId}`)
        const data = await response.json();
        const newProductCart = { ...data, amount: amount > 1 ? amount : 1 };
        setCart([...cart, newProductCart])
        setCartLocalStorage([...cart, newProductCart])
        toast.success('Produto adicionado ao carrinho')
      }
    } catch (e) {
      toast.error('Erro na adição do produto');
    }
  };

  const removeProduct = (productId) => {
    try {
      const verifExistProduct = cart.some(product => product.id === productId)
      if (verifExistProduct) {
        const cartDeletedItem = cart.filter(product => (product.id !== productId))
        setCart(cartDeletedItem)
        setCartLocalStorage(cartDeletedItem)
      } else {
        throw new Error('Erro na remoção do produto')
      }

    } catch (e) {
      const result = (e).message;
      toast.error(result);
    }
  };

  const updateProductAmount = async ({
    productId,
    amount,
  }) => {
    try {
      const verifExistProduct = cart.some(product => product.id === productId)

      if (verifExistProduct) {
        const response = await fetch(`${msEndpoint}/shopping/product/${productId}`)
        const responseProductStock = await response.json();
        const amountStockProduct = responseProductStock?.supply

        const newCart = cart.map(product => {
          let productQuantity = { ...product }
          if (product.id === productId) {
            if (amount <= amountStockProduct && amount >= 1) {
              productQuantity = { ...product, amount: amount }
            } else {
              throw new Error("Quantidade solicitada fora de estoque")
            }
          }
          return productQuantity
        })
        setCart(newCart)
        setCartLocalStorage(newCart)
      } else {
        throw new Error('Erro na alteração de quantidade do produto')
      }

    } catch (e) {
      const result = (e).message;
      toast.error(result);
    }
  };

  return (
    <CartContext.Provider
      value={{ cart, addProduct, removeProduct, updateProductAmount }}
    >
      <ToastContainer
        position="top-right"
        pauseOnFocusLoss
        autoClose={1200}
        theme='colored'
        draggable
      />
      {children}
    </CartContext.Provider>
  );
}

function setCartLocalStorage(cart) {
  localStorage.setItem('@Mastertech:cart', JSON.stringify(cart))
}

export function useCart() {
  const context = useContext(CartContext);
  return context;
}
