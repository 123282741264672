import React, { useState } from "react";
import "./SetNewPassword.scss";
import Input from "../../components/common/Input/Input";
import { usePasswordField } from "../../hooks/formHooks";
import Loading from "../../components/common/Loading/Loading";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { apiChangePasswordRequest } from "../../api/password";

const SetNewPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [password, setPassword, validPassword] = usePasswordField("");
  const [
    confirmPassword,
    setConfirmPassword,
    validConfirmPassword
  ] = usePasswordField("");
  const [displayError, setDisplayError] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [resetPasswordComplete, setResetPasswordComplete] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setIsFetching(true);
    if (!validPassword && !validConfirmPassword) {
      setDisplayError(true);
      setIsFetching(false);
    } else if (password === confirmPassword) {
      setDisplayError(false);
      const payload = {
        password: password,
        token: token
      };
      apiChangePasswordRequest(payload)
        .then(() => {
          setIsFetching(false);
          setResetPasswordComplete(true);
        })
        .catch(() => {
          setIsFetching(false);
          setResetPasswordComplete(false);
          setFeedback(
            "Tivemos um problema, por favor tente novamente ou entre em contato com oi@mastertech.com.br"
          );
        });
    } else {
      setIsFetching(false);
      setFeedback("As senhas não coincidem");
    }
  };

  return (
    <section className="SetNewPassword">
      <form
        className={!resetPasswordComplete ? "visible" : ""}
        onSubmit={handleSubmit}
      >
        <p>Por favor, digite uma nova senha nos campos abaixo:</p>
        <div>
          <Input
            label="Nova senha:"
            value={password}
            valid={validPassword}
            placeholder="nova senha"
            displayError={displayError}
            updateState={setPassword}
            required
            type="password"
            errorMessage="A senha deve conter 6 caracteres com números e letras."
          />
          <Input
            label="Confirmar nova senha:"
            value={confirmPassword}
            valid={validConfirmPassword}
            placeholder="confirmar senha"
            displayError={displayError}
            updateState={setConfirmPassword}
            required
            type="password"
            errorMessage="A senha deve conter 6 caracteres com números e letras."
          />
        </div>
        <div>
          <p>{feedback}</p>
          <button>Cadastrar</button>
        </div>
      </form>
      <div className={` updated ${resetPasswordComplete ? "visible" : ""}`}>
        <h4>Cadastro atualizado com sucesso.</h4>
        <p>Qualquer dúvida entre em contato conosco.</p>
        <button onClick={() => navigate("/")}>
          Voltar para Home
        </button>
      </div>
      <Loading active={isFetching} />
    </section>
  );
};

export default SetNewPassword;
