import { processResponse, baseEndpoint } from "./common";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Token bd67e4eaa045cdae7c4c05ea235005d091881150"
};

export const apiLeadRegister = payload =>
  fetch(baseEndpoint, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload)
}).then(processResponse);
