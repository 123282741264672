import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/images/arrow.svg';
import email from '../../assets/images/mail.png';
import phone from '../../assets/images/phone.png';
import { ReactComponent as DownArrow } from '../../assets/images/thin-arrow.svg';
import whatsApp from '../../assets/images/whatsapp-logo.png';
import B2BCourseCard from '../../components/common/B2BCourseCard/B2BCourseCard';
import RotatingText from '../../components/common/RotatingText/RotatingText';
import { getCourseDetails, listCourses } from '../../redux/actions/courses';
import { areaEnumTranslator, categoryEnumTranslator, FormatEnumTranslator } from '../../utils/enumTranslator';
import { replaceWithLineBreaks } from '../../utils/formating';
import { isMobile } from '../../utils/responsiveness';
import './B2BCourse.scss';

const B2BCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const course = useSelector(state => state.courses.courseDetail);
  const dispatch = useDispatch();
  const referencesCourse = useSelector(state => state.courses.list);
  const [listReferences, setListReferences] = useState([])

  const getQueryString = (list) => {
    let string = '?'
    for (let item of list) {
      if (string.length > 1) {
        string += "&"
      }
      string += "ids=" + item;
    }
    return string?.replace('?ids=', '')
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCourseDetails(id)).then((reponse) => {
      setListReferences([])
      reponse?.references?.length >= 1 && dispatch(listCourses({ ids: getQueryString(reponse?.references) }))
        .then((json) => setListReferences(json))
    });
  }, [id])

  return (
    <main className='B2BCourse'>
      <section className='course-info'>
        {!isMobile() && <BackArrow role="button" onClick={() => navigate("/para-empresas")} />}
        <p className='course-format'>{categoryEnumTranslator(course?.category)} em {areaEnumTranslator(course?.area)}</p>
        <h2 dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course?.name) }}></h2>
        <article className='course-info-details'>
          <h3>FORMATO</h3>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(FormatEnumTranslator(course?.format)) }}></p>
          <h3>DURAÇÃO</h3>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course?.estimateDuration) }}></p>
          <h3>PARA QUEM</h3>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course?.targetAudience) }}></p>
        </article>
        <article className='course-info-description'>
          <h2 dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course?.descriptionTitle) }}></h2>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(course?.description) }}></p>
          <DownArrow />
        </article>
        {!isMobile() && <RotatingText />}
      </section>
      <section className='course-related'>
        <h3>PEÇA UM ORÇAMENTO</h3>
        <a href={`https://wa.me/5511919522455?text="Olá, gostaria de conhecer a formação em ${course?.name}"`} rel='noopener noreferrer' target="_blank">mande mensagem <img src={whatsApp} alt='whatsapp' /></a>
        <a href={`"mailto:oi@mastertech.com.br?subject=orçamento de curso&body=Olá, gostaria de conhecer a formação de ${course?.name}`} rel='noopener noreferrer'>oi@mastertech.com.br <img src={email} alt='email' /></a>
        <a href='tel:1191952-2455' rel='noopener noreferrer'>11 91952-2455 <img src={phone} alt='telefone' /></a>
        {!isMobile() && listReferences?.items?.length > 1 && <h3>FORMAÇÕES RELACIONADAS</h3>}
        <div className='related-courses'>
          {listReferences?.items?.map((courseRelated, index) => {
            return <B2BCourseCard key={index} courseData={courseRelated} onClick={() => navigate(`/para-empresas/cursos/${courseRelated.id}`)} />
          })}
        </div>
      </section>
    </main>
  )
}

export default B2BCourse;
