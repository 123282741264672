import React, { useEffect, useState } from 'react';
import { msEndpoint } from '../../../api/common';
import { useCpfInput, useEmailField, usePhoneInput, useTextField } from '../../../hooks/formHooks';
import { postalCodeFormatter } from '../../../utils/formating';
import { searchPostalCode } from '../../../utils/searchPostalCode';
import Button from '../../common/Button/Button';
import "./Ticket.scss";
import { MaskIdentity } from '../../../utils/MaskIdentity';

const Ticket = ({ operationTicket, finish, fetching, payload}) => {
  const [errorForm, setErrorForm] = useState()
  const [postalCode, setPostalCode] = useTextField("");
  const [stateCode, setStateCode, validStateCode] = useTextField("");
  const [street, setStreet, validStreet] = useTextField("");
  const [city, setCity, validCity] = useTextField("");
  const [number, setNumber, validNumber] = useTextField("");
  const [locality, setLocality, validLocality] = useTextField("");
  const [validPostalCode, setValidPostalCode] = useState(false)
  const [displayError, setDisplayError] = useState(false);
  const [postalCodeData, setPostalCodeData] = useState();
  const STATES = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"]
  const [errorPaymentTicket, setErrorPaymentTicket] = useState(false);
  const [email, setEmail] = useEmailField("");
  const [phone, setPhone] = usePhoneInput("");
  const [cpf, setCpf, validCPF] = useCpfInput("");
  const [buyerName, setBuyerName] = useTextField("");
  const SITE_KEY = "6Ld4alkkAAAAAPqdXB34v2SsNnjgQjI956VDtg7s";
  const error = {}

  const checkPostalCode = (number) => {
    searchPostalCode(number, setPostalCodeData)
  }

  useEffect(() => {
    postalCodeData?.validPostal ? setValidPostalCode(true) :
      setValidPostalCode(false)
    setLocality(postalCodeData?.bairro)
    setStreet(postalCodeData?.logradouro)
    setStateCode(postalCodeData?.uf)
    setCity(postalCodeData?.localidade)
  }, [postalCodeData]);

  const payloadData = {
    buyerName,
    email,
    phone,
    identifier: cpf
  }

  const verifyInitialForm = () => {
    if (!validCPF) {
      error["error_identifier"] = "error_identifier"
    }

    Object.keys(payloadData).forEach((item) => {
      if (payloadData[item] === '') {
        error[item] = item;
      }

      if (Object.keys(error).length > 0) {
        setErrorForm({ error });
        return;
      }
    });

    let emptyObject = Object.keys(error).length === 0;

    return emptyObject;
  }

  const submitBoleto = () => {
    if (payload()) {
      if (verifyInitialForm()) {
        if (!validCity || !validLocality ||
          !validNumber || validPostalCode ||
          !validStateCode || !validStreet) {
          setDisplayError(true)
        } else {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(captcha => {

              fetching(true);
              const paylodTicket = {
                products: payload(),
                buyer: {
                  name: buyerName,
                  socialName: buyerName,
                  identifier: payloadData.identifier.replace(/[^0-9]/g, ''),
                  email: payloadData.email,
                  phone: payloadData.phone,
                  address: {
                    street,
                    number,
                    locality,
                    city,
                    state: city,
                    stateCode,
                    postalCode: postalCode.replace(/[^0-9]/g, ''),
                    country: "Brasil",
                  }
                },
                payment: {
                  method: "BOLETO",
                  installments: 1
                }
              }
              const purchaseTicket = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Captcha': captcha },
                body: JSON.stringify(paylodTicket)
              };

              fetch(`${msEndpoint}/shopping/purchase`, purchaseTicket)
                .then(response => response.json())
                .then(data => {
                  operationTicket({ boleto: true, url: data?.payment?.boleto_url })
                  fetching(false);
                  finish(true)
                  localStorage.setItem('@Mastertech:cart', [])
                })
                .catch(() => {
                  fetching(false);
                  setErrorPaymentTicket(true)
                  setTimeout(() => {
                    setErrorPaymentTicket(false)
                  }, 5000);
                })
            });
          });
        }
      }
    }
  }

  return (
    <div className='Ticket'>
      <div className='container-ticket'>
        <div className='input-box-two'>
          <div className='input-box'>
            <label>Estado</label>
            <select className={displayError && !validStateCode && 'error'} value={stateCode} onChange={e => setStateCode(e.target.value)}>
              <option value="">Selecionar</option>
              {
                STATES.map(state => <option key={state} value={state}>{state}</option>)
              }
            </select>
            <p>{displayError && !validStateCode && "Selecione algum campo"}</p>
          </div>
          <div className='input-box'>
            <label htmlFor="postalcode">CEP</label>
            <input
              maxLength={9}
              className={validPostalCode && 'error'}
              type="text"
              name='postalcode'
              onChange={e => {
                validPostalCode && setValidPostalCode(false)
                setPostalCode(e.target.value)
                checkPostalCode(e.target.value)
              }}
              value={postalCodeFormatter(postalCode)}
            />
            <p>{validPostalCode && "CEP inválido"}</p>
          </div>
        </div>
        <div className='two-box'>
          <div className='input-box'>
            <label htmlFor="city">Cidade</label>
            <input
              className={displayError && !validCity && 'error'}
              type="text"
              name='city'
              onChange={e => setCity(e.target.value)}
              value={city}
            />
            <p>{displayError && !validCity && "Preencha o campo"}</p>
          </div>
          <div className='input-box'>
            <label htmlFor="district">Bairro</label>
            <input
              className={displayError && !validLocality && 'error'}
              type="text"
              name='district'
              onChange={e => setLocality(e.target.value)}
              value={locality}
            />
            <p>{displayError && !validLocality && "Preencha o campo"}</p>
          </div>
        </div>
        <div className='two-box'>
          <div className='input-box'>
            <label htmlFor="street">Endereço</label>
            <input
              className={displayError && !validStreet && 'error'}
              type="text"
              name='street'
              onChange={e => setStreet(e.target.value)}
              value={street}
            />
            <p>{displayError && !validStreet && "Preencha o campo"}</p>
          </div>
          <div className='input-box'>
            <label htmlFor="number">Número</label>
            <input
              className={displayError && !validNumber && 'error'}
              type="text"
              name='number'
              onChange={e => setNumber(e.target.value)}
              value={number}
            />
            <p>{displayError && !validNumber && "Preencha o campo"}</p>
          </div>
        </div>
        <div className='input-box'>
          <label htmlFor="email">E-mail</label>
          <input
            className={errorForm?.error?.email && 'error'}
            type="text"
            name='email'
            onChange={e => { setEmail(e.target.value) || delete errorForm?.error?.email }}
            value={email}
          />
          <p>{errorForm?.error?.email && "Preencha o campo"}</p>
        </div>
        <div className='input-box-three'>
          <div className='input-box'>
            <label htmlFor="buyerName">Nome Completo</label>
            <input
              className={errorForm?.error?.buyerName && 'error'}
              autoComplete="cc-number"
              type="text"
              name='buyerName'
              onChange={e => { setBuyerName(e.target.value) || delete errorForm?.error?.buyerName }}
              value={buyerName}
            />
            <p>{errorForm?.error?.buyerName && "Preencha o campo"}</p>
          </div>
          <div className='input-box'>
            <label htmlFor="identifier">CPF ou CNPJ</label>
            <MaskIdentity
              type="text"
              name='identifier'
              className={errorForm?.error?.identifier && 'error' || errorForm?.error?.error_identifier && 'error'}
              value={cpf}
              onChange={(event) => {
                setCpf(event.target.value) || delete errorForm?.error?.identifier && delete errorForm?.error?.error_identifier
              }}
            />
            <p>{errorForm?.error?.identifier && "Preencha o campo" || errorForm?.error?.error_identifier && "CPF ou CNPJ inválido"}</p>
          </div>
          <div className='input-box'>
            <label htmlFor="phone">Celular</label>
            <input
              className={errorForm?.error?.phone && 'error'}
              type="text"
              name='phone'
              onChange={e => { setPhone(e.target.value) || delete errorForm?.error?.phone }}
              value={phone}
            />
            <p>{errorForm?.error?.phone && "Preencha o campo"}</p>
          </div>
        </div>
      </div>
      <p>Depois do pagamento, fique atento ao seu e-mail para receber os dados de acesso ao produto (verifique também a caixa de SPAM).</p>
      <Button onClick={() => submitBoleto()} active className={`payment-button ${errorPaymentTicket && "error"}`}>{errorPaymentTicket ? "ERRO AO EFETUAR A COMPRA" : "GERAR BOLETO"}</Button>
    </div>
  );
}

export default Ticket;
