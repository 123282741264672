import React, { useEffect, useState } from 'react';
import B2BCourseCard from '../../components/common/B2BCourseCard/B2BCourseCard';
import Button from '../../components/common/Button/Button';
import './ForCompanies.scss';
import SearchInput from '../../components/common/SearchInput/SearchInput';
import { useNavigate } from 'react-router-dom';
import ContactForm from '../../components/ui/ContactForm/ContactForm';
import { useDispatch, useSelector } from 'react-redux';
import { listCourses } from '../../redux/actions/courses';

const ForCompanies = ({setSendToBack}) => {
  const [categoryFilter, setCategoryFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courses = useSelector(state => state.courses)

  const sendToBack = () => {
    if (window.scrollY > 289) {
      setSendToBack(true)
    } else {
      setSendToBack(false)
    }
  }

  useEffect(() => {
    sendToBack()
    window.addEventListener("scroll", sendToBack)
    dispatch(listCourses())
  }, [])

  useEffect(() => { 
    let filters = {category: categoryFilter, area: areaFilter, type: "businesses", search, pageSize: 30}
    dispatch(listCourses(filters))
  }, [categoryFilter, areaFilter, search])

  const toggleFilter = (filter, updateState, value) => {
    if (filter !== value) {
      updateState(value)
    }
    else {
      updateState("")
    }
  }

  return (
    <main className='ForCompanies'>
      <div className='filters'>
        <h3>Formações</h3>
        <div className='filter-group'>
          <p>Tipo</p>
          <div className='filter-group-buttons'>
            <Button onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "experience")} active={categoryFilter === "experience"}>Vivências</Button>
            <Button onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "formation")} active={categoryFilter === "formation"}>Formações</Button>
          </div>
        </div>
        <div className='filter-group'>
          <p>Área</p>
          <div className='filter-group-buttons'>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "tech")} active={areaFilter === "tech"}>Tech</Button>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "ágil")} active={areaFilter === "ágil"}>Ágil</Button>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "digital")} active={areaFilter === "digital"}>Digital</Button>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "inovação")} active={areaFilter === "inovação"}>Inovação</Button>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "dados")} active={areaFilter === "dados"}>Dados</Button>
          </div>
        </div>
        <SearchInput search={search} updateState={setSearch} />
      </div>
      <section>
        {courses?.list?.totalItems == 0 ?
        <div className='no-course'>
          <h3>AINDA NÃO TEMOS SOLUÇÃO NESSA CATEGORIA :(</h3>
            <p>Entre em contato com a gente. Vamos criar uma formação personalizada para você.</p>
            <ContactForm/>
        </div>
        : courses.list?.items?.map((course, index) =>
          course?.type === "BUSINESSES" && <B2BCourseCard courseData={course} onClick={() => {navigate(`/para-empresas/cursos/${course.id}`)}} key={index} /> )}
      </section>
    </main>
  )
}

export default ForCompanies;
