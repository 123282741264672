import React, { useState, useRef, useEffect } from 'react';
import { msEndpoint, shoppingCardPK } from '../../../api/common';
import { useBirthDateInput, useCpfInput, useEmailField, usePhoneInput, useTextField } from '../../../hooks/formHooks';
import { getCardType } from '../../../utils/getCardFlag';
import Button from '../../common/Button/Button';
import "./CardEcommerce.scss";
import { MaskIdentity } from '../../../utils/MaskIdentity';
import Cart from '../Cart/Cart';

const CardEcommerce = ({ payload, shoppingId, finish, fetching, notify, process }) => {
  const [errorForm, setErrorForm] = useState()
  const [cardNumber, setCardNumber] = useTextField("");
  const [cvv, setCVV] = useTextField("");
  const [buyerName, setBuyerName] = useTextField("");
  const [operation, setOperation] = useTextField(1);
  const [activeOperation, setActiveOperation] = useState(false);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [email, setEmail] = useEmailField("");
  const [phone, setPhone] = usePhoneInput("");
  const [cpf, setCpf, validCPF] = useCpfInput("");
  const [birthday, setBirthday] = useBirthDateInput("");
  const [showSeparator, setShowSeparator] = useState(true);
  const [encryptedCard, setEncryptedCard] = useState('')
  const [clickedButton, setClickedButton] = useState('')
  const [errorCard, setErrorCard] = useState([])
  const [installmentsCard, setInstallmentsCard] = useState(1)
  const error = {}
  const SITE_KEY = "6Ld4alkkAAAAAPqdXB34v2SsNnjgQjI956VDtg7s";

  async function validateCard(card) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products: shoppingId?.map((item) => { return { amount: item?.amount, productId: item?.id } }), cardBin: cardNumber.substring(0, 6) })
    };
    const response = await fetch(`${msEndpoint}/shopping/purchase/simulate`, requestOptions);
    const data = await response.json();

    data?.error_messages && notify("Número de cartão inválido")
    data.payment_methods.credit_card[getCardType(card)]?.installment_plans.length > 10 ? setOperation(6) : setOperation(1)

    return data && setInstallmentsCard(data.payment_methods.credit_card[getCardType(card)]?.installment_plans);
  }

  const validateMonth = (value, oldValue) => {
    const validator =
      value.length <= 2 &&
      /^\d*$/.test(value) &&
      (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 12));
    if (validator) {
      return value;
    }
    monthRef.current.value = oldValue;
    return oldValue;
  };

  const handleMonth = e => {
    const target = e.target;
    const validatedValue = validateMonth(target.value, month);
    setMonth(validatedValue);
    if (validatedValue) {
      if (validatedValue.length >= 2) {
        setShowSeparator(true);
        yearRef.current.focus();
      } else {
        setShowSeparator(false);
        monthRef.current.focus();
      }
    }
    if (yearRef.current.value) {
      setShowSeparator(true);
    }
  };

  const validateYear = (value, oldValue) => {
    const validator =
      value.length <= 2 &&
      /^\d*$/.test(value) &&
      (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 99));
    if (validator) {
      return value;
    }
    yearRef.current.value = oldValue;
    return oldValue;
  };

  const handleYear = e => {
    const target = e.target;
    const validatedValue = validateYear(target.value, year);
    setYear(validatedValue);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);


  const payloadData = {
    email,
    phone,
    identifier: cpf
  }

  const verifyInitialForm = () => {
    if (!validCPF) {
      error["error_identifier"] = "error_identifier"
    }

    Object.keys(payloadData).forEach((item) => {
      if (payloadData[item] === '') {
        error[item] = item;
      }

      if (Object.keys(error).length > 0) {
        setErrorForm({ error });
        return;
      }
    });

    let emptyObject = Object.keys(error).length === 0;

    return emptyObject;
  }


  /*eslint-disable */
  const handleSubmit = async (event) => {
    setClickedButton(true)
    event.preventDefault()
    if (payload()) {
      if (verifyInitialForm()) {
        if (installmentsCard?.length >= 0) {
          const card = PagSeguro.encryptCard({
            publicKey: shoppingCardPK,
            holder: buyerName,
            number: cardNumber,
            expMonth: month,
            expYear: 20 + year,
            securityCode: cvv,
          })

          if (card.hasErrors) {
            notify("Houve um erro inesperado, tente novamente mais tarde!")
            const messageFixer = {
              INVALID_NUMBER: 'Número do cartão inválido',
              INVALID_EXPIRATION_MONTH: 'Mês de vencimento inválido',
              INVALID_EXPIRATION_YEAR: 'Ano de vencimento inválido',
              INVALID_SECURITY_CODE: 'Código de segurança inválido',
              INVALID_HOLDER: 'Nome do titular do cartão inválido',
              INVALID_PUBLIC_KEY: 'Chave pública inválida',
            }
            Object.keys(card.errors).forEach((item) => {
              error[card.errors[item].code] = messageFixer[card.errors[item].code]

              if (Object.keys(error).length > 0) {
                setErrorCard({ error });
                return;
              }
            });
          } else {
            window.grecaptcha.ready(() => {
              window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(captcha => {

                fetching(true);
                setEncryptedCard(
                  card.encryptedCard
                )
                const payloadCard = {
                  products: payload(),
                  buyer: {
                    name: buyerName,
                    socialName: buyerName,
                    identifier: payloadData.identifier.replace(/[^0-9]/g, ''),
                    email: payloadData.email,
                    phone: payloadData.phone
                  },
                  payment: {
                    method: "CREDIT_CARD",
                    encryptedCard: card.encryptedCard,
                    cardBin: cardNumber.substring(0, 6),
                    installments: operation
                  }
                }

                const purchaseCard = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json', 'Captcha': captcha },
                  body: JSON.stringify(payloadCard)
                };

                card.encryptedCard &&
                  fetch(`${msEndpoint}/shopping/purchase`, purchaseCard)
                    .then(serverPromise => {
                      if (serverPromise?.status === 201 ||
                        serverPromise?.status === 200) {
                        serverPromise.json()
                          .then((response) => {
                            if (response?.payment?.status === "PAID" ||
                              response?.payment.status === "IN_ANALYSIS") {
                              process("PAID")
                              fetching(false);
                              finish(true)
                            } else if (response?.payment?.status === "AUTHORIZED") {
                              process("AUTHORIZED")
                              fetching(false);
                              finish(true)
                            } else {
                              notify("Ocorreu um erro na sua compra, verifique os dados do cartão")
                              fetching(false);
                            }
                            localStorage.setItem('@Mastertech:cart', [])
                          })
                          .catch(() => {
                            fetching(false);
                            notify("Houve um erro inesperado, tente novamente mais tarde!")
                          })
                        setClickedButton(false)
                      } else if (serverPromise?.status === 400) {
                        fetching(false);
                        notify("Ocorreu um erro na sua compra, verifique os dados do cartão")
                      } else {
                        fetching(false);
                        notify("Houve um erro inesperado,  tente novamente mais tarde!")
                      }
                      setClickedButton(false)
                    })
                    .catch(() => {
                      fetching(false);
                      notify("Houve um erro inesperado, tente novamente mais tarde!")
                      setClickedButton(false)
                    });
              });
            });
          }
        } else {
          setClickedButton(false)
          setInstallmentsCard("")
          setErrorCard({ error: { INVALID_NUMBER: "Número do cartão inválido" } })
        }
      } else {
        setClickedButton(false)
      }
    } else {
      setClickedButton(false)
    }
  }

  return (
    <>
      <div className='CardEcommerce'>
        <div className='input-box'>
          <label htmlFor="card">Número do Cartão</label>
          <input
            onBlur={() => validateCard(cardNumber)}
            className={errorCard?.error?.INVALID_NUMBER && 'error'}
            inputMode="numeric" pattern="[0-9\s]{13,19}" autoComplete="cc-number" maxLength="19"
            type="tel"
            name='card'
            onChange={e => { setCardNumber(e.target.value) || delete errorCard?.error?.INVALID_NUMBER }}
            value={cardNumber.replace(/\s/g, '')}
          />
          <p>{errorCard?.error?.INVALID_NUMBER && errorCard?.error?.INVALID_NUMBER}</p>
        </div>
        <div className='input-box-two'>
          <div>
            <label htmlFor="validate">Validade (MM/AA)</label>
            <div className='fake-input'>
              <input
                className={errorCard?.error?.INVALID_EXPIRATION_MONTH && 'error'}
                data-id="month-input"
                ref={monthRef}
                radix="."
                value={month}
                autoComplete="off"
                name="month"
                onChange={e => { handleMonth(e) || delete errorCard?.error?.INVALID_EXPIRATION_MONTH }}
                onFocus={e => {
                }}
                onBlur={e => {

                }}
              />
              {showSeparator && <span data-id="seperator">/</span>}
              <input
                className={errorCard?.error?.INVALID_EXPIRATION_YEAR && 'error'}
                data-id="year-input"
                ref={yearRef}
                autoComplete="off"
                value={year}
                name="year"
                onChange={e => { handleYear(e) || delete errorCard?.error?.INVALID_EXPIRATION_YEAR }}
              />
            </div>
            <p>{errorCard?.error?.INVALID_EXPIRATION_MONTH || errorCard?.error?.INVALID_EXPIRATION_YEAR && "Confira o mês e ano de vencimento do cartão"}</p>
          </div>
          <div className='code-cvv'>
            <label htmlFor="cvv">Cod. Segurança</label>
            <input
              className={errorCard?.error?.INVALID_SECURITY_CODE && 'error'}
              inputMode="numeric" autoComplete="cc-number" maxLength="4"
              type="tel"
              name='cvv'
              onChange={e => { setCVV(e.target.value) || delete errorCard?.error?.INVALID_SECURITY_CODE }}
              value={cvv}
            />
            <p>{errorCard?.error?.INVALID_SECURITY_CODE && errorCard?.error?.INVALID_SECURITY_CODE}</p>
          </div>
        </div>
        <div className='input-box'>
          <label htmlFor="card-name">Nome impresso no cartão</label>
          <input
            className={errorCard?.error?.INVALID_HOLDER && 'error'}
            autoComplete="cc-number"
            type="text"
            name='card-name'
            onChange={e => { setBuyerName(e.target.value) || delete errorCard?.error?.INVALID_HOLDER }}
            value={buyerName}
          />
          <p>{errorCard?.error?.INVALID_HOLDER && errorCard?.error?.INVALID_HOLDER}</p>
        </div>
        <div className='input-box'>
          <label htmlFor="email">E-mail</label>
          <input
            className={errorForm?.error?.email && 'error'}
            type="text"
            name='email'
            onChange={e => { setEmail(e.target.value) || delete errorForm?.error?.email }}
            value={email}
          />
          <p>{errorForm?.error?.email && "Preencha o campo"}</p>
        </div>
        <div className='input-box-three'>
          <div className='input-box'>
            <label htmlFor="identifier">CPF ou CNPJ</label>
            <MaskIdentity
              type="text"
              name='identifier'
              className={errorForm?.error?.identifier && 'error' || errorForm?.error?.error_identifier && 'error'}
              value={cpf}
              onChange={(event) => {
                setCpf(event.target.value) || delete errorForm?.error?.identifier && delete errorForm?.error?.error_identifier
              }}
            />
            <p>{errorForm?.error?.identifier && "Preencha o campo" || errorForm?.error?.error_identifier && "CPF ou CNPJ inválido"}</p>
          </div>
          <div className='input-box'>
            <label htmlFor="phone">Celular</label>
            <input
              className={errorForm?.error?.phone && 'error'}
              type="text"
              name='phone'
              onChange={e => { setPhone(e.target.value) || delete errorForm?.error?.phone }}
              value={phone}
            />
            <p>{errorForm?.error?.phone && "Preencha o campo"}</p>
          </div>
        </div>
        <div className='installments-container'>
          {!activeOperation ?
            installmentsCard && installmentsCard.length > 0 && <>
              <div className='initial-installments'>
                <p>{installmentsCard[5] ? "6x" : "1x"} <span>R$ {installmentsCard[5] ? installmentsCard[5]?.installment_value/100 : installmentsCard[0]?.installment_value/100}</span></p>
                <button onClick={() => setActiveOperation(true)}>Editar Parcelas</button>
              </div>
            </>
            :
            <div className='input-box'>
              <label htmlFor="card-name">Parcelas</label>
              <select
                value={operation}
                onChange={(e) => { setOperation(e.target.value) }}>
                {
                  installmentsCard && installmentsCard.length >= 0 ? (
                    installmentsCard.map(item => (
                      <option key={item.installments} value={item.installments}>
                        {
                          `${item.installments}x `
                        }
                        {
                          (item.installment_value/100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                        }
                        {
                          item.interest_free ? ' (sem juros)' : (
                            ` | Total - ${(item?.amount?.value/100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} `
                          )
                        }
                      </option>
                    ))
                  ) : (
                    <option value="">SELECIONAR</option>
                  )
                }
              </select>
            </div>}
          <Button onClick={(e) => !clickedButton && handleSubmit(e)} active className="payment-button">PAGAR E GARANTIR MINHA VAGA</Button>
        </div>
      </div>
    </>
  );
}

export default CardEcommerce;
