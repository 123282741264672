import { headers, processResponse, baseSiteEndPoint } from "./common";

export const apiVerifyEmailRequest = email =>
  fetch(`${baseSiteEndPoint}/student/${email}`, {
    method: "GET",
    headers: headers
  }).then(processResponse);

export const apiNewPasswordRequest = email =>
  fetch(`${baseSiteEndPoint}/user/new_password`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ email })
  }).then(processResponse);

export const apiChangePasswordRequest = payload =>
  fetch(`${baseSiteEndPoint}/user/new_password/change_password`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload)
  }).then(processResponse);
  