export const searchPostalCode = (event, saveData) =>  {
  if (event?.length < 9) {
    return;
  } else {
    fetch(`https://viacep.com.br/ws/${event}/json/`, { mode: 'cors' })
      .then((res) => res.json(res))
      .then((data) => {
        if (data.hasOwnProperty("erro")) {
          saveData({validPostal: true})
        } else {
          saveData(data)
        }
      })
      .catch(err => console.log(err));
  }
}
