import React, { useState } from "react";
import { apiLeadRegister } from "../../../api/lead";
import { useEmailField, usePhoneInput, useTextField } from "../../../hooks/formHooks";
import Button from "../../common/Button/Button";
import './ContactFormMobile.scss';

const ContactFormMobile = ({ inverted, setLoading }) => {
  const [name, setName, validName] = useTextField("");
  const [phone, setPhone, validPhone] = usePhoneInput("");
  const [email, setEmail, validEmail] = useEmailField("");
  const [company, setCompany, validCompany] = useTextField("");
  const [message, setMessage, validMessage] = useTextField("");
  const [displayError, setDisplayError] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const inputs = [
    {
      tagName: "input",
      label: "Qual seu nome?",
      type: "text",
      placeholder: "nome",
      valid: validName,
      value: name,
      onChange: setName
    },
    {
      tagName: "input",
      label: "Qual seu telefone?",
      type: "text",
      placeholder: "telefone",
      valid: validPhone,
      value: phone,
      onChange: setPhone
    },
    {
      tagName: "input",
      label: "Qual seu email?",
      type: "email",
      placeholder: "email",
      valid: validEmail,
      value: email,
      onChange: setEmail
    },
    {
      tagName: "input",
      label: "Qual a sua empresa?",
      type: "text",
      placeholder: "empresa",
      valid: validCompany,
      value: company,
      onChange: setCompany,
    },
    {
      tagName: "textarea",
      label: "Digite sua mensagem?",
      type: "text",
      placeholder: "mensagem",
      valid: validMessage,
      value: message,
      onChange: setMessage
    }
  ];

  const handleSubmit = () => {
    const payload = {
      name: name,
      company: company,
      email: email,
      telephone: phone,
      origin: "site",
      fields: {
        message: message,
      }
    };
    setLoading(true);
    apiLeadRegister(payload)
      .then(() => {
        setLoading(false);
        setFeedback(true);
        resetForm();
        setFeedbackMessage("OBRIGADO, ENTRAREMOS EM CONTATO.");
      })
      .catch(() => {
        setLoading(false);
        setFeedback(true);
        resetForm();
        setFeedbackMessage("ERRO AO ENVIAR A MENSAGEM, ENTRE EM CONTATO (11)91952-2455.");
      });
  };

  const resetForm = () => {
    setTimeout(() => {
      setFeedback(false);
    }, 2000);

    setName("");
    setPhone("");
    setEmail("");
    setCompany("");
    setMessage("");
  };

  const next = (event) => {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }

  const keyPress = (event, valid, action) => {
    if (event.key === "Enter") {
      if (valid) {
        action(event);
      } else {
        setDisplayError(true);
      }
    }
  };

  return <div className={`ContactFormMobile ${inverted ? "inverted" : ""}`}>
    <form action="" onSubmit={e => e.preventDefault()}>
      {inputs.map((input, index) => input.tagName === 'input'
        ?
        <input key={index} type={input.type}
          value={input.value}
          onChange={e => input.onChange(e.target.value)}
          placeholder={input.placeholder}
          onKeyUp={
            index < inputs.length - 1
              ? e => keyPress(e, input.valid, next)
              : e => keyPress(e, input.valid, handleSubmit)
          } />
        : <textarea key={index} type={input.type}
          value={input.value}
          onChange={e => input.onChange(e.target.value)}
          placeholder={input.placeholder}
          onKeyUp={
            index < inputs.length - 1
              ? e => keyPress(e, input.valid, next)
              : e => keyPress(e, input.valid, handleSubmit)
          } />
      )}
      <div
        className="feedback-container"
        style={{ display: feedback ? "flex" : "none" }}
      >
        <p className="feedback">{feedbackMessage}</p>
      </div>
      <Button className='contact'>ENVIAR</Button>
    </form>
  </div>
}

export default ContactFormMobile;
