import React, { useState } from 'react';
import { apiLeadRegister } from '../../api/lead';
import { ReactComponent as Person } from "../../assets/images/person-24px.svg";
import Loading from "../../components/common/Loading/Loading";
import Select from "../../components/common/Select/Select";
import { useBirthDateInput, useCpfInput, useEmailField, useTextField } from "../../hooks/formHooks";
import "./HolderPortal.scss";

const HolderPortal = () => {
  const [name, setName] = useTextField("");
  const [email, setEmail] = useEmailField("");
  const [message, setMessage] = useTextField("");
  const [birthdate, setBirthdate] = useBirthDateInput("");
  const [cpf, setCpf] = useCpfInput("");
  const [operation, setOperation] = useTextField("Consulta de compartilhamento de dados");
  const [feedback, setFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    e.preventDefault();
    const payload = {
      name: name,
      email: email,
      origin: window.location.pathname,
      fields: {
        cpf: cpf,
        birthDate: birthdate,
        message: message,
        operation: operation
      }
    };
    setLoading(true);
    apiLeadRegister(payload)
      .then(() => {
        setLoading(false);
        setFeedback(true);
        setFeedbackMessage("OBRIGADO, ENTRAREMOS EM CONTATO.");
        clearFields();
      })
      .catch(() => {
        setLoading(false);
        setFeedback(true);
        setFeedbackMessage("ERRO AO ENVIAR A MENSAGEM, ENTRE EM CONTATO (11)91952-2455.");
        clearFields();

        setTimeout(() => {
          setFeedback(false);
        }, 4000);
      });
  };

  const clearFields = () => {
    setName("");
    setEmail("");
    setCpf("");
    setMessage("");
    setBirthdate("");
  };

  const changeMessage = e => {
    e.preventDefault();
    setMessage(e.target.value)
  }

  return (
    <div className="HolderPortal">
      <div className="container-holder-portal">
        <div className="form-holder-portal">
          <form>
            <div class="holder-dpo">
              <h2>Aqui você pode fazer solicitações acerca dos seus dados pessoais coletados pela Mastertech</h2>
              <div>
                <Person style={{ marginRight: 20 }} />
                <div>
                  <h3>Camila Achutti</h3>
                  <p>DPO (encarregada)</p>
                  <h4>camila@mastertech.com.br</h4>
                </div>
              </div>
            </div>
            <input
              type="text"
              placeholder="Nome Completo"
              onChange={e => setName(e.target.value)}
              value={name}
            />
            <input
              type="text"
              placeholder="CPF"
              onChange={e => setCpf(e.target.value)}
              value={cpf}
            />
            <input
              type="birthdate"
              placeholder=" Data de Nascimento"
              value={birthdate}
              onChange={e => setBirthdate(e.target.value)}
            />
            <input
              type="text"
              placeholder="E-mail"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <Select
              updateState={setOperation}
              value={operation}
              displayError={false}>
              <option selected value="" disabled> Selecione a operação</option>
              <option value="Consulta de compartilhamento de dados">Consulta de compartilhamento de dados</option>
              <option value="Revogação dos consentimentos">Revogação dos consentimentos</option>
              <option value="Consulta simples">Consulta simples</option>
              <option value="Correção ou alteração dos dados">Correção ou alteração dos dados</option>
              <option value="Esquecimento ou exclusão dos dados">Esquecimento ou exclusão dos dados</option>
              <option value="Oposição ao tratamento dos dados">Oposição ao tratamento dos dados</option>
              <option value="Portabilidade dos dados">Portabilidade dos dados</option>
            </Select>
            <textarea
              value={message}
              onChange={changeMessage}
              placeholder="Detalhes da solicitação"
            />
            <div
              className="feedback-container"
              style={{ display: feedback ? "flex" : "none" }}
            >
              <p className="feedback">{feedbackMessage}</p>
            </div>
            <div className="info-holder">
              <p >*Os dados informados aqui não serão armazenados.</p>
              <p >Somente os utilizaremos para o processamento da solicitação.*</p>
            </div>
            <button onClick={(e) => handleChange(e)} className='submit-button'>
              Solicitar
            </button>
          </form>
          <Loading active={loading} />
        </div>
      </div>
    </div>
  );
};

export default HolderPortal;
