import React from 'react';
import './SearchInput.scss';
import {ReactComponent as SearchIcon} from '../../../assets/images/search.svg';

const SearchInput = ({search, updateState, onSubmit}) => {
  return (
    <form className='SearchInput'>
      <input type='text' placeholder='Busca' value={search} onChange={e => updateState(e.target.value)}/>
      <div>
        <SearchIcon/>
      </div>
    </form>
    )
  }
  
  export default SearchInput;
