import { apiGetCourse, apiGetCourses } from "../../api/courses";
import { getCourse, getCourseFailed, getCourses, getCoursesFailed, getCoursesSucceeded, getCourseSucceeded } from "../action-creators/courses"

const createListCourses = filters => dispatch => {
  dispatch(getCourses());
  return apiGetCourses(filters)
    .then((json) => {
      dispatch(getCoursesSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((err) => {
      dispatch(getCoursesFailed(err));
      return Promise.reject();
    })
}

export const listCourses = (filters) => (dispatch) => dispatch(createListCourses(filters))

const createGetCourse = id => dispatch => {
  dispatch(getCourse());
  return apiGetCourse(id)
    .then((json) => {
      dispatch(getCourseSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((err) => {
      dispatch(getCourseFailed(err));
      return Promise.reject();
    })
}

export const getCourseDetails = (id) => (dispatch) => dispatch(createGetCourse(id))
