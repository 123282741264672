import { useState } from "react";
import { validCPF } from "../utils/cpfValidate";
import { validCNPJ } from "../utils/cnpjValidate";
import { localizeDate } from "../utils/DateHelper";

export const useBirthDateInput = initialValue => {
  initialValue = localizeDate(initialValue);
  const [birthday, setBirthday] = useState(initialValue);

  const handleChange = value => {
    value = value.replace(/\D/g, "");
    const mask =
      value.length > 4 ? "$1/$2/$3" : value.length > 2 ? "$1/$2" : "$1";
    value = value.replace(/(\w{1,2})(\w{0,2})(\w{0,4}).*/, mask);
    setBirthday(value);
  };

  return [birthday, handleChange];
};

export const useCpfInput = initialValue => {
  const [cpf, setCpf] = useState(initialValue);
  const [valid, setValid] = useState(true);

  const handleChange = value => {
    setCpf(value);
    value?.replace(/[^0-9]/g, '')?.length <= 11 ?
    setValid(validCPF(value)) :
    setValid(validCNPJ(value));
  };

  return [cpf, handleChange, valid];
};

export const useEmailField = initialValue => {
  const emailValidationRegex = /^\S+@\S+\.\S+$/;

  const [email, setEmail] = useState(initialValue);
  const [valid, setValid] = useState(emailValidationRegex.test(initialValue));

  const handleChange = newValue => {
    setEmail(newValue);
    setValid(emailValidationRegex.test(newValue));
  };
  return [email, handleChange, valid];
};

export const usePasswordField = initialValue => {
  const passwordValidationRegex = /(?=.*[a-z])(?=.*[0-9])(?=.{6,})/;
  const [password, setPassword] = useState(initialValue);
  const [valid, setValid] = useState(
    passwordValidationRegex.test(initialValue)
  );

  const handleChange = newValue => {
    setPassword(newValue);
    setValid(passwordValidationRegex.test(newValue));
  };
  return [password, handleChange, valid];
};

export const usePhoneInput = initialValue => {
  const phoneValidationRegex = /^\(\d{2}\) \d{4,5}-\d{4}/;
  const [phone, setPhone] = useState(initialValue);
  const [valid, setValid] = useState(phoneValidationRegex.test(initialValue));

  const handleChange = value => {
    value = value.replace(/\D/g, "");

    const mask =
      value.length > 6 ? "($1) $2-$3" : value.length > 2 ? "($1) $2" : "$1";
    const groupRegex =
      value.length > 10
        ? /^(\d{1,2})(\d{0,5})(\d{0,4}).*/
        : /^(\d{1,2})(\d{0,4})(\d{0,4}).*/;

    value = value.replace(groupRegex, mask);
    setPhone(value);
    setValid(phoneValidationRegex.test(value));
  };
  return [phone, handleChange, valid];
};

export const useTextField = initialValue => {
  const [text, setText] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");

  const handleChange = newValue => {
    setText(newValue);
    setValid(newValue !== "");
  };
  return [text, handleChange, valid];
};
