import React, { useState } from 'react';
import './Solutions.scss';
import { ReactComponent as Arrow } from '../../assets/images/simple-arrow.svg';
import { ReactComponent as ArrowButton } from '../../assets/images/arrow.svg';
import Button from '../../components/common/Button/Button';
import { solutions } from '../../constants/solutions';
import RotatingText from '../../components/common/RotatingText/RotatingText';
import { isMobile } from '../../utils/responsiveness';
import { Link } from 'react-router-dom';

const Solutions = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleChangeIndex = (quantifier) => {
    if (carouselIndex + quantifier > solutions.length - 1) {
      setCarouselIndex(0);
    } else if(carouselIndex + quantifier < 0) {
      setCarouselIndex(solutions.length - 1)
    } else {
      setCarouselIndex(carouselIndex + quantifier)
    }
  }

	return (
    <main className="Solutions">
      {!isMobile() && <Arrow onClick={() => handleChangeIndex(1)}/>}
      {isMobile()
        ? <div className='solutions'>
          {solutions.map((solution, key) => <>
            <img src={`/images/solutions/${solution.image}`} />
            <h2>{solution.name}</h2>
            <p>{solution.description}</p>
            <a href={`"mailto:oi@mastertech.com.br?subject=orçamento de curso&body=Olá, gostaria de conhecer a formação de ${solution.name}`} active>vem conversar com a gente <ArrowButton /></a>
          </>)}
        </div>
        : <>
          <img className={carouselIndex == 2 && "lune"} src={`/images/solutions/${solutions[carouselIndex].image}`} />
          <h2>{solutions[carouselIndex].name}</h2>
          <p>{solutions[carouselIndex].description}</p>
          <a href={solutions[carouselIndex].url} target="_blank" active>vem conversar com a gente <ArrowButton /></a>
        </>}
      {!isMobile() && <Arrow onClick={() => handleChangeIndex(1)}/>}
      {!isMobile && <div className='platforms'>
        <Button onClick={() => {setCarouselIndex(0)}} simple active={carouselIndex === 0}>E2W</Button>
        <Button onClick={() => {setCarouselIndex(1)}} simple active={carouselIndex === 1}>ALEXANDRIA</Button>
        <Button onClick={() => {setCarouselIndex(2)}} simple active={carouselIndex === 2}>LUNE</Button>
      </div>}
      {!isMobile() && <div className='rotating-text-wrapper'>
			  <RotatingText/>
      </div>}
		</main>
		)
	}
	
export default Solutions;
