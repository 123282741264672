import { GET_COURSE, GET_COURSES, GET_COURSES_FAILED, GET_COURSES_SUCCEEDED, GET_COURSE_FAILED, GET_COURSE_SUCCEEDED } from "../constants/courses";

export const courses = (state = {
  list: [],
  courseDetail: {},
  isFetching: false
}, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_COURSES:
    case GET_COURSE:
      newState.isFetching = true;
      return newState;
    case GET_COURSES_SUCCEEDED:
      newState.error = {};
      newState.list = action.data;
      newState.isFetching = false;
      return newState;
    case GET_COURSES_FAILED:
      newState.list = [];
      newState.error = action.error;
      newState.isFetching = false;
      return newState;
    case GET_COURSE_SUCCEEDED:
      newState.error = {};
      newState.courseDetail = action.data;
      newState.isFetching = false;
      return newState;
    case GET_COURSE_FAILED:
      newState.courseDetail = [];
      newState.error = action.error;
      newState.isFetching = false;
      return newState;
    default:
      return newState;
  }
}
