import React, { useEffect, useState } from 'react';
import "./MultiEcommerce.scss";
import { replaceWithLineBreaks } from '../../utils/formating';
import { useTextField } from '../../hooks/formHooks';
import { ReactComponent as Card } from '../../assets/images/card.svg';
import { ReactComponent as TicketIcon } from '../../assets/images/ticket.svg';
import { ReactComponent as Pix } from '../../assets/images/pix.svg';
import CardEcommerce from '../../components/ui/CardEcommerce/CardEcommerce';
import Loading from '../../components/common/Loading/Loading';
import FinishPurchase from './FinishPurchase/FinishPurchase';
import Ticket from '../../components/ui/Ticket/Ticket';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProductDetails } from '../../redux/actions/product';
import { ReactComponent as CarWhite } from "../../assets/images/car-white.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Question from '../../components/common/questions/Question/Question';
import { formatPrice } from '../../utils/format';
import { useNavigate } from 'react-router-dom';


const MultiEcommerce = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const courseFetching = useSelector(state => state.courses.isFetching);
  const course = useSelector(state => state.courses.courseDetail);
  const product = useSelector(state => state.product.data);
  const dispatch = useDispatch();
  const [activePaymentTemporary, setActivePaymentTemporary] = useState(true);
  const [finishPurchaseOpen, setFinishPurchaseOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [errorForm, setErrorForm] = useState()
  const [displayAllErrors, setDisplayAllErrors] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [operationTicket, setOperationTicket] = useState({})
  const error = {}
  const SITE_KEY = "6Ld4alkkAAAAAPqdXB34v2SsNnjgQjI956VDtg7s";
  const notify = (text) => toast.error(text);
  const [processFinish, setProcessFinish] = useTextField("");

  const [cart, setCart] = useState(() => {
    const storagedCart = localStorage.getItem('@Mastertech:cart')

    if (storagedCart) {
      return JSON.parse(storagedCart);
    }

    return [];
  });


  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }

    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProductDetails({ ids: id }))
  }, [])

  useEffect(() => {
    if (openDetails) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openDetails]);


  const [questionValues, setQuestionValues] = useState({})
  const getQuestionValue = (productId, ticketIndex, questionId) => {
    if (questionValues[productId]) {
      if (questionValues[productId][ticketIndex]) {
        if (questionValues[productId][ticketIndex][questionId]) {
          return questionValues[productId][ticketIndex][questionId]
        }
      }
    }

    return ""
  }

  const setQuestionValue = (productId, ticketIndex, questionId, newValue) => {
    let newQuestionValues = structuredClone(questionValues)
    if (!newQuestionValues[productId]) {
      newQuestionValues[productId] = {}
    }
    if (!newQuestionValues[productId][ticketIndex]) {
      newQuestionValues[productId][ticketIndex] = {}
    }
    newQuestionValues[productId][ticketIndex][questionId] = newValue
    setQuestionValues(newQuestionValues)
  }

  const questionValuesToPayload = () => {
    try {
      let products = [];
      for (let item of cart) {
        let product = {
          productId: item.id,
          amount: item.amount,
          answerGroups: Object.keys(questionValues?.[item.id])?.map(ticketId => {
            return {
              questions: Object.keys(questionValues?.[item.id]?.[ticketId]).map(questionId => {
                return {
                  id: questionId,
                  answer: questionValues?.[item.id]?.[ticketId]?.[questionId]
                }
              })
            }
          })
        }

        products.push(product)
      }
      return products;
    } catch {
      setDisplayAllErrors(true)
      window.scrollTo(0, 0);
      return false;
    }
  }

  const total =
    formatPrice(
      cart.reduce((sumTotal, product) => {
        return sumTotal += product.amount * product.value/100
      }, 0)
    )

  return (
    <>
    {cart?.length <= 0 && navigate(`/`)} 
    {product && !courseFetching ?
      <div className={`MultiEcommerce ${finishPurchaseOpen && "finish"}`}>
        <ToastContainer
          position="top-center"
          pauseOnFocusLoss
          autoClose={5000}
          type="error"
          theme='colored'
          draggable
        />
        <div className='container-purchase'>
          <div className='container-questions'>
            {cart?.map((cartItem) => {
              return <div className='questions'>
                <div className='info-question'>
                  <h2 dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(cartItem?.name) }}></h2>
                  <p>início: 15/08/2023 à 20/10/2023</p>
                  <p>horario: 19h às 21h, terças e quintas</p>
                  <h3>Informação do participante</h3>
                </div>
                <div className='gap-questions'>
                  {Array.apply(null, Array(cartItem.amount)).map(function (x, i) {
                    return <div>
                      <p className='title-question'><strong>ingresso nº {i + 1}</strong> - {cartItem.name}</p>
                      {cartItem?.questions.map(question => {
                        return <Question question={question}
                          displayAllErrors={displayAllErrors}
                          setDisplayAllErrors={setDisplayAllErrors}
                          errorForm={errorForm}
                          answer={getQuestionValue(cartItem.id, i, question.id)}
                          setAnswer={(newValue) => setQuestionValue(cartItem.id, i, question.id, newValue)}
                        />
                      })}
                    </div>;
                  })}
                </div>
              </div>
            })}
          </div>

          <div className='container-border'>
            <div className='container-buttons'>
              <button className={activePaymentTemporary ? 'active' : ''} onClick={() => setActivePaymentTemporary(true)}>
                <Card />
                Cartão de Crédito
              </button>
              <button className={!activePaymentTemporary ? 'active' : ''} onClick={() => setActivePaymentTemporary(false)}>
                <TicketIcon />
                Boleto
              </button>
              <button className='pix'>
                <Pix />
                Pix (em breve)
              </button>
            </div>
            {activePaymentTemporary ?
              <CardEcommerce shoppingId={cart.map((item) => { return {amount: item?.amount, id: item?.id} })}
                notify={notify}
                payload={() => questionValuesToPayload()}
                process={setProcessFinish}
                fetching={setIsFetching} finish={setFinishPurchaseOpen} />
              : <Ticket course={course} product={product} operationTicket={setOperationTicket}
                payload={() => questionValuesToPayload()}
                fetching={setIsFetching} finish={setFinishPurchaseOpen} />}
          </div>
        </div>
        <div className='wallet-cart' >
          <div className='title-product'>
            <p><span>TOTAL</span></p>
            <p> <CarWhite className='car-icon' /> {total}</p>
          </div>
          {cart.map((item) => {
            return (
              <div className='sub-details-product'>
                <div className='infos-product'>
                  <p><strong>{item?.name}</strong></p>
                  <p>{formatPrice(item?.value/100)}</p>
                </div>
                <p><strong>{item?.amount}</strong></p>
              </div>
            )
          })}
        </div>
      </div>
      : <Loading active />}
      {finishPurchaseOpen && <FinishPurchase processFinish={processFinish} course={course} operationTicket={operationTicket} />}
      <div className={openDetails && "active-modal"}></div>
      {isFetching && <Loading active />}
    </>
  );
}

export default MultiEcommerce;
