import React from 'react';
import Button from '../../../components/common/Button/Button';
import "./FinishPurchase.scss";

const FinishPurchase = ({ operationTicket, processFinish }) => {

  const renderMessage = () => {
    if (processFinish === "PAID"){
      return {
        title: "Compra efetuada com sucesso!",
        describe: "Em breve você receberá a confirmação por e-mail e o cronograma dos encontros."
      }
    } else if (operationTicket) {
      return {
        title: "Aguardando Pagamento",
        describe: "Aguardando a confirmação do pagamento do seu boleto bancário por parte do banco emissor",
      }
    }
     else {
      return {
        title: "Estamos processando a sua compra.",
        describe: "No momento em que seu pedido é confirmado, você receberá um e-mail com essa confirmação.",
      }
    } 
  }

  return (
    <div className='FinishPurchase'>
      <div className='container-finish'>
        <p>PARABÉNS!</p>
        <h3>{renderMessage().title}</h3>
        <p className='description'>{renderMessage().describe}</p>
        <p><span>Bem vindo(a)!</span></p>
        {operationTicket?.boleto && <a href={operationTicket?.url} target="_blank">
          <Button active className="finish-button">GERAR BOLETO</Button>
        </a>}
      </div>
    </div>
  );
}

export default FinishPurchase;
