import React from 'react';
import './Cart.scss';
import { replaceWithLineBreaks } from '../../../utils/formating';
import { ReactComponent as Decrement } from "../../../assets/images/decrement.svg";
import { ReactComponent as Increment } from "../../../assets/images/increment.svg";
import { useCart } from '../../../hooks/useCart';
import { formatPrice } from '../../../utils/format';
import { useSelector } from 'react-redux';

const Cart = ({ setOpenCart }) => {
  const { cart, removeProduct, updateProductAmount } = useCart();
  const cartFormatted = cart.map(product => ({
    id: product.id,
    title: product.name,
    stopSalesOn: product.stopSalesOn,
    shoppingId: product.shoppingId,
    price: product.value,
    investment: product.investment,
    amount: product.amount,
    subTotal: product.amount * product.price
  }))

  const total =
    formatPrice(
      cart.reduce((sumTotal, product) => {
        return sumTotal += product.amount * product.value/100
      }, 0)
    )

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank').focus();
  };


  function handleProductIncrement(product) {
    const cartItemIncrement = {
      productId: product.id,
      amount: product.amount + 1
    }
    updateProductAmount(cartItemIncrement)
  }

  function handleProductDecrement(product) {
    const cartItemIncrement = {
      productId: product.id,
      amount: product.amount - 1
    }
    updateProductAmount(cartItemIncrement)
  }

  function handleRemoveProduct(productId) {
    removeProduct(productId)
  }


  const getQueryString = (list) => {
    let string = '?'
    for (let item of list) {
      if (string.length > 1) {
        string += "&"
      }
      string += "ids=" + item;
    }
    return string?.replace('?ids=', '')
  }

  return (
    <div className='Cart'>
      <div className='info-cart'>
        <button onClick={() => setOpenCart(false)}>
          <img src={require("../../../assets/images/arrow-product.png")} alt="Lixeira" />
        </button>
        <h5>carrinho</h5>
      </div>
      <div className='container-cart'>
        {cartFormatted.map((product, key) => (
          <>
            <div className='product-details' key={key}>
              <div className='title-product'>
                <p><span dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(product?.title) }}></span></p>
                <p>{formatPrice(product?.price/100)}</p>
              </div>
              <div className='sub-details-product'>
                <div className='infos-product'>
                  <p>{formatPrice(product?.price/100)}</p>
                  <p className='green'>em até 6x de {((product.price/100) / 6)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                  <p>Vendas até {new Date(product?.stopSalesOn).toLocaleDateString()}</p>
                </div>
                <div className='increment-product'>
                  <div className='contain-product'>
                    <button
                      // disabled={product.amount <= 1}
                      onClick={() => handleProductDecrement(product)}
                    >
                      <Decrement style={{ cursor: 'pointer' }} />
                    </button>
                    <p>{product.amount}</p>
                    <button
                      data-testid="decrement-product"
                      // disabled={product.amount <= 1}
                      onClick={() => handleProductIncrement(product)}
                    >
                      <Increment style={{ cursor: 'pointer' }} />
                    </button>
                  </div>
                  <button onClick={() => handleRemoveProduct(product.id)}>
                    <img src={require("../../../assets/images/bin.png")} alt="Lixeira" />
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </>
        ))}
      </div>

      <div className='subtotal-products'>
        <p>Subtotal</p>
        <p><strong>{total}</strong></p>
      </div>

      <div className='finish-products'>
        <button onClick={() => {
          let listIds = [];
          cartFormatted.map((product) => {
            listIds.push(product.id)
          })

          listIds.length >= 1 &&
            openLinkInNewTab(`/compra/${getQueryString(listIds)}`)
        }}>FINALIZAR COMPRA</button>
      </div>
    </div>
  );
}

export default Cart;