export const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
];

export const localizeDate = (birthdate, splitChar = "-", joinChar = "/") => {
  if (birthdate && birthdate.includes(splitChar)) {
    return birthdate
      ? birthdate
          .split(splitChar)
          .reverse()
          .join(joinChar)
      : null;
  }
  return "";
};

export const reverseData = (birthdate, splitChar = "/", joinChar = "-") => {
  if (birthdate && birthdate.includes(splitChar)) {
    return birthdate
      ? birthdate
          .split(splitChar)
          .reverse()
          .join(joinChar)
      : null;
  }
  return "";
};

export const dateToISOFormat = birthday => localizeDate(birthday, "/", "-");

export const dateToLongFormat = date => {
  const newDate = new Date(date);
  const day = newDate.getUTCDate();
  const month = months[newDate.getUTCMonth()];
  const year = newDate.getUTCFullYear();
  const improvedDate = `${day} de ${month} de ${year}`;
  return improvedDate;
};
