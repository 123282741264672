import React, { useEffect, useState } from 'react';
import "./Question.scss";
import Select from "../../../common/Select/Select";
import { MaskIdentity } from '../../../../utils/MaskIdentity';
import { localizeDate } from '../../../../utils/DateHelper';

const Question = ({ question, setAnswer, answer, displayAllErrors, errorForm, setDisplayAllErrors }) => {
  const type = question?.type;
  const [valid, setValid] = useState(false);
  const typeEnums = {
    INTEGER: "number",
    CPF: "cpf",
    PHONE: "phone",
    STRING: "text",
    DATE: "date",
    EMAIL: "email"
  }

  useEffect(() => {
    if (displayAllErrors) {
      answer?.length <= 0 && setValid(true)
    }
  }, [displayAllErrors, answer]);

  const renderInput = () => {
    if (typeEnums[type] === "phone") {
      const phoneValidationRegex = /^\(\d{2}\) \d{4,5}-\d{4}/;
      const mask =
        answer.length > 6 ? "($1) $2-$3" : answer.length > 2 ? "($1) $2" : "$1";
      const groupRegex =
        answer.length > 10
          ? /^(\d{1,2})(\d{0,5})(\d{0,4}).*/
          : /^(\d{1,2})(\d{0,4})(\d{0,4}).*/;

      answer = answer.replace(groupRegex, mask);

      return (
        <div className='input-box'>
          <label htmlFor="phone">{question?.prompt}</label>
          <input
            className={errorForm?.error[question?.id] && 'error' || valid && 'error' }
            type="text"
            name='phone'
            required
            onChange={e => {
              setAnswer(e.target.value)
              delete errorForm?.error[question?.id]
              setValid(false)
            }}
            value={answer}
          />
          <p>{errorForm?.error[question?.id] && "Verifique o número" || valid && "Verifique o número"}</p>
        </div>
      )
    } else if (typeEnums[type] === "cpf") {
      return (
        <MaskIdentity
          type="text"
          name='identifier'
          value={answer}
          onChange={e => { setAnswer(e.target.value) }}
        />
      )
    } else if (typeEnums[type] === "email") {
      const emailValidationRegex = /^\S+@\S+\.\S+$/;

      return (
        <div className='input-box'>
          <label htmlFor="email">{question?.prompt}</label>
          <input
            className={errorForm?.error[question?.id] && 'error' || valid && 'error'}
            type="email"
            name='email'
            required
            onChange={e => { setAnswer(e.target.value) || delete errorForm?.error[question?.id] }}
            value={answer}
            onFocus={() => setValid(false)}
            onBlur={() => !emailValidationRegex.test(answer) && setValid(true)}
          />
          <p>{errorForm?.error[question?.id] && "Campo de email inválido" || valid && "Campo de email inválido"}</p>
        </div>
      )
    } else if (typeEnums[type] === "text") {
      return (
        <div className='input-box'>
          <label htmlFor="text">{question?.prompt}</label>
          <input
            className={errorForm?.error[question?.id] && 'error' || valid && 'error'}
            type="text"
            required
            name='text'
            onChange={e => {
              setAnswer(e.target.value)
              delete errorForm?.error[question?.id]
              setValid(false)
            }}
            value={answer}
          />

          <p>{  valid && "Preencha o campo"  || errorForm?.error[question?.id] && "Preencha o campo" }</p>
        </div>
      )
    } else if (typeEnums[type] === "date") {
      answer = localizeDate(answer);
      answer = answer.replace(/\D/g, "");
      const mask =
        answer.length > 4 ? "$1/$2/$3" : answer.length > 2 ? "$1/$2" : "$1";
      answer = answer.replace(/(\w{1,2})(\w{0,2})(\w{0,4}).*/, mask);
      return (
        <div className='input-box'>
          <label htmlFor="text">{question?.prompt}</label>
          <input
            className={errorForm?.error[question?.id] && 'error'}
            type="text"
            required
            name='text'
            onChange={e => setAnswer(e.target.value) || delete errorForm?.error[question?.id]}
            value={answer}
          />
          <p>{errorForm?.error[question?.id] && "Preencha o campo"}</p>
        </div>
      )
    } else {
      return (
        <div className='input-box'>
          <label htmlFor="text">{question?.prompt}</label>
          <input
            className={errorForm?.error[question?.id] && 'error' || displayAllErrors && 'error'}
            type="number"
            required
            name='text'
            onChange={e => setAnswer(e.target.value) || delete errorForm?.error[question?.id] || setDisplayAllErrors(false)}
            value={answer}
          />
          <p>{errorForm?.error[question?.id] || displayAllErrors && "Preencha o campo"}</p>
        </div>
      )
    }
  }

  return (
    <div className="Question">
      {type === "LIST"
        ? (<Select label={question?.prompt} options={question.values} updateState={setAnswer}
          displayError={false} value={answer} />)
        : renderInput(question)
      }
    </div>
  );
};

export default Question;
