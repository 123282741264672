import React from 'react';
import { categoryEnumTranslator, FormatEnumTranslator } from '../../../utils/enumTranslator';
import { replaceWithLineBreaks } from '../../../utils/formating';
import './B2CCourseCard.scss';

const B2CCourseCard = ({ courseData, onClick }) => {  
  return (
    <div className='B2CCourseCard' onClick={onClick}>
      <p>{categoryEnumTranslator(courseData.category)} {FormatEnumTranslator(courseData.format)}</p>
      <h2 dangerouslySetInnerHTML={{__html: replaceWithLineBreaks(courseData.name)}}></h2>
      <p>{courseData.cardDescription}</p>
      <button onClick={onClick}>SAIBA MAIS</button>
    </div>
    )
  }
  
export default B2CCourseCard;
