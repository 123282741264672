import {
    GET_PRODUCT,
    GET_PRODUCT_FAILED,
    GET_PRODUCT_SUCCEEDED
  } from "../constants/product";
  
  export const product = (
    state = {
      isFetching: false,
      data: {},
      error: {},
    }, action) => {
    let newState = {...state};
  
    switch (action.type) {
      case GET_PRODUCT:
        newState.isFetching = true;
        return newState;
  
      case GET_PRODUCT_SUCCEEDED:
        newState.isFetching = false;
        newState.data = action.data;
        newState.error = {};
        return newState;
  
      case GET_PRODUCT_FAILED:
        newState.isFetching = false;
        newState.error = action.error;
        newState.data = {};
        return newState;
  
      default:
        return newState;
    }
  };
  