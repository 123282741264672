import React from "react";
import "./TermsConditions.scss";

const TermsConditions = () => (
  <section className="TermsConditions">
    <h2>TERMOS E CONDIÇÕES</h2>
    <div>
      <div>
        <p>
          A vaga do participante no curso somente será reservada após a devida
          comprovação de pagamento confirmada pela instituição financeira. A
          <span> TRANSFERÊNCIA</span> de uma inscrição para outra turma ou o{" "}
          <span>CANCELAMENTO</span> de uma inscrição somente poderão ser
          realizados mediante solicitação por e-mail ou contato com a equipe de
          vendas, pelas seguintes condições:
        </p>
        <p>
          <span>&#8226;</span> Aviso com 15 (quinze) dias ou mais de
          antecedência: devolução integral do valor investido em até 10 (dez)
          dias ou transferência para outro curso de igual valor, a critério do
          participante.
        </p>
        <p>
          <span>&#8226;</span> Aviso com 03 (três) dias à 14 (catorze) dias de
          antecedência: devolução de 50% do valor investido ou transferência
          para outro curso de igual valor, a critério do participante.
        </p>
        <p>
          <span>&#8226;</span> Aviso com menos de 72 horas de antecedência: não
          haverá devolução do valor investido mas há a possibilidade da
          transferência para um outro curso a critério do participante e
          disponibilidade de vagas.
        </p>
      </div>
      <div>
        <p>
          A qualquer momento a Mastertech poderá realizar promoções referentes a
          inscrição no curso. Este fato, no entanto, não dará direito a
          bonificação, descontos ou brindes aos participantes inscritos
          anteriormente a promoção. Em caso de uso de cupons de desconto, são
          válidos apenas para os cursos anunciados de acordo com as restrições
          de uso descritas na promoção. É permitida a utilização de apenas um
          cupom de desconto por compra. Caso esta regra não seja cumprida, a
          Mastertech se reserva no direito de cancelar a inscrição e, restituir
          o valor total investido.
        </p>
        <p>
          A Mastertech poderá cancelar ou adiar o curso com antecedência de 1 a
          7 dias da data de realização apenas em casos de desastres naturais,
          paralisações ou problemas de saúde do instrutor que o impossibilitem
          de ministrar a aula. Nesse caso, caberá ao participante a escolha do
          reembolso integral do valor pago, a inscrição no mesmo curso em outra
          data ou ainda a transferência para outro curso do mesmo valor. Em
          nenhuma hipótese será reembolsado despesas extras do participante,
          como passagens ou hospedagem.
        </p>
      </div>
    </div>
  </section>
);

export default TermsConditions;
