export const categoryEnumTranslator = (enumToBeTranslated) => {
  switch (enumToBeTranslated) {
    case "EXPERIENCE":
      return 'Vivência';
    case "FORMATION":
      return 'Formação';
    default:
      return '';
  } 
}
export const areaEnumTranslator = (enumToBeTranslated) => {
  switch (enumToBeTranslated) {
    case "TECH":
      return 'tech';
    case "AGIL":
      return 'ágil';
    case "DIGITAL":
      return 'digital';
    case "DADOS":
      return 'dados';
    case "INOVACAO":
      return 'inovação';
    default:
      return '';
  } 
}
export const TypeEnumTranslator = (enumToBeTranslated) => {
  switch (enumToBeTranslated) {
    case "people":
      return 'people';
    case "businesses":
      return 'businesses';
    default:
      return '';
  } 
}
export const FormatEnumTranslator = (enumToBeTranslated) => {
  switch (enumToBeTranslated) {
    case "REMOTE":
      return 'Remoto';
    case "FACE_TO_FACE":
      return 'Presencial';
    default:
      return 'remoto ou presencial';
  } 
}
