import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationLink.scss';

const NavigationLink = ({href, children, active, highlight, editClass }) => {
    return (
    <Link className={`NavigationLink ${active ? 'active' : ''} ${highlight ? 'highlight' : ''} ${editClass ?  "editClass" : ''}`} to={href}>
        {children}
    </Link>);
}

export default NavigationLink;
