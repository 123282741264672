import React from "react";
import "./NotFoundPage.scss";

const NotFoundPage = () => (
  <section className="NotFoundPage">
    <div>
      <h2>404</h2>
      <h2>PÁGINA NÃO ENCONTRADA</h2>
    </div>

    <p>Mas o que significa 404?</p>
    <p>
      Quando você acessa um recurso na Internet, os servidores respondem com um
      código numérico de três dígitos, que representa o que aconteceu com a
      requisição.
    </p>
    <p>Os códigos tem o seguinte formato:</p>
    <ul>
      <li>1xx - Informações</li>
      <li>2xx - Confirmações e sucessos</li>
      <li>3xx - Redirecionamentos</li>
      <li>4xx - Erros do cliente</li>
      <li>5xx - Erros do servidor</li>
    </ul>
    <p>
      Uma requisição válida para uma das nossas páginas deveria responder com o
      número <span>200</span> para o seu navegador. Porque será que você veio
      parar aqui?
    </p>
    <p>
      O erro 404 quer dizer que não encontramos a página que você acessou.
      Confira o endereço no seu navegador, ou entre em contato através do{" "}
      <a className="tm-email" href="mailto:oi@mastertech.com.br">
        oi@mastertech.com.br
      </a>{" "}
      para informar o problema.
    </p>
  </section>
);

export default NotFoundPage;
