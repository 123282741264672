export const solutions = [
  {
    name: "E2W",
    description: "Nosso banco digital de conhecimento. Ou melhor, nossa carteira de investimentos. Mas esqueça títulos e ações. Aqui, os ativos são as suas habilidades. Uma plataforma de mensuração, análise e planejamento.",
    image: "e2w.png",
    alt: "print da dashboard de e2w",
    url: "https://e2w.mastertech.com.br/"
  },
  {
    name: "ALEXANDRIA",
    description: "Explicando em uma abstração: é um álbum de gotos. Daqueles antigos, que continham apenas os registros mais importantes, onde escrevia-se legendas para localizá-los no espaço-tempo, transformando-os numa experiência coletiva de apreciação e revisitação. Troque fotografias por informação e monte sua própria curadoria com nosso indexador de conteúdo.",
    image: "alexandria.png",
    alt: "print da home de alexandria",
    url: "https://alexandria.mastertech.com.br/"
  },
  {
    name: "LUNE",
    image: "lune.png",
    description: "Áudios também podem ser fonte de estudo. Autonomia e interatividade em formato de pequenas mensagens de voz para os que têm o que dizer, para os que desejam ouvir, para todos que querem trocar e expandir.",
    alt: "print da home de lune",
    url: ""
  }
]
