import React, {useEffect, useState} from 'react';
import "./Cookies.css";
import {getItemFromLocalStorage} from "../../../utils/localStorage";
import {useLocation} from "react-router-dom";

const Cookies = () => {
  const location = useLocation();
  const { pathname } = location;
  const [activeCookies, setActiveCookies] = useState(false);

  const closeCookies = () => {
    setActiveCookies(true)
    window.localStorage.setItem('cookie', 'bdf9d18336b9564d3487ee2702c75bf5');
  }

  useEffect(() => {
    if(pathname === "/politica-de-privacidade" ||
      getItemFromLocalStorage("cookie") ||
      activeCookies){
      document.body.style.overflow = 'unset'
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [pathname, activeCookies]);


  return (
    <div id="cookie-banner-lgpd" className="cookie-banner-lgpd-visible cookie-banner-lgpd-animated"
         style={{display: `${activeCookies ? "none" : "flex"}`}}>
      <div className="cookie-banner-lgpd-container">
        <div className="cookie-banner-lgpd_text-box"><span className="cookie-banner-lgpd_text">
          Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços.
          Ao utilizar nossos serviços, você concorda com tal monitoramento. Informamos ainda que atualizamos
          nossa <a
          className="cookie-banner-lgpd-link cookie-banner-lgpd_privacy-link" target="_blank"
          style={{color: "#0069de"}}
          href="politica-de-privacidade">Política de Privacidade</a> <span>.</span> </span></div>
        <div className="cookie-banner-lgpd_button-box">
          <button onClick={() => closeCookies()}
             className="cookie-banner-lgpd_accept-button"
             style={{backgroundColor: "#0069de"}}>PROSSEGUIR</button>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
