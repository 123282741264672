import { apiGetProduct } from "../../api/product";
import { getProduct, getProductFailed, getProductSucceeded } from "../action-creators/product";

export const createGetProduct = (payload) => dispatch => {
  dispatch(getProduct());
  return apiGetProduct(payload)
    .then((json) => {
      dispatch(getProductSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((error) => {
      dispatch(getProductFailed(error));
      return Promise.reject(error);
    })
};

export const getProductDetails = (id) => (dispatch) => dispatch(createGetProduct(id))
