import React from 'react';

const WhatsAppButton = () => {
  const phoneNumber = "+5511919522455";
  const message = "Olá! Gostaria de saber mais sobre os cursos oferecidos pela Mastertech.";

  const handleClick = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <button
      onClick={handleClick}
      style={{
        backgroundSize: 'contain',
        background: 'url("../images/WhatsApp.svg") no-repeat center center',
        border: 'none',
        bottom: '5%',
        cursor: 'pointer',
        height: '80px',
        position: 'fixed',
        right: '2%',
        width: '80px',
        zIndex: 100
      }}
    >
    </button>
  );
};

export default WhatsAppButton;
