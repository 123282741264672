export const replaceWithLineBreaks = (text) => {
  let newText = text?.replace("//", "<wbr />")
  newText = newText?.replace(/\\n/g, "<br />") // aparentemente a api tem colocado um \ a mais no \n ficando \\n -> quando resolvido remover essa linha
  return newText?.replace(/\n/g, "<br />")
}

export const postalCodeFormatter = code => {
  let newPostalCode = code?.replace(/\D/g, '')
  ?.replace(/(\d{5})(\d)/, '$1-$2')
  .replace(/(-\d{3})\d+?$/, '$1')
  return newPostalCode
}
