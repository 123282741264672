export const loadStateFromLocalStorage = () => {
    try {
      const serializedState = window.localStorage.getItem("state");
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      return undefined;
    }
  };
  export const saveStateAtLocalStorage = state => {
    try {
      const serializedState = JSON.stringify(state);
      window.localStorage.setItem("state", serializedState);
    } catch (error) {
      throw error;
    }
  };
  export const getItemFromLocalStorage = key => {
    try {
      return window.localStorage.getItem(key);
    } catch (error) {
      throw error;
    }
  };
  export const saveItemAtLocalStorage = (key, item) => {
    try {
      const serializedItem =
        typeof item === "string" ? item : JSON.stringify(item);
      window.localStorage.setItem(key, serializedItem);
    } catch (error) {
      throw error;
    }
  };
  
  export const deleteItemFromLocalStorage = key => {
    try {
      return window.localStorage.removeItem(key);
    } catch (error) {
      throw error;
    }
  };
  