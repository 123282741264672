import React, { useState } from "react";
import "./PasswordRecovery.scss";
import { useEmailField } from "../../hooks/formHooks";
import Loading from "../../components/common/Loading/Loading";
import Input from "../../components/common/Input/Input";
import { apiNewPasswordRequest, apiVerifyEmailRequest } from "../../api/password";

const PasswordRecovery = () => {
  const [emailFound, setEmailFound] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [email, setEmail, validEmail] = useEmailField("");
  const [displayError, setDisplayError] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setIsFetching(true);
    if (!validEmail) {
      setDisplayError(true);
      setIsFetching(false);
    } else {
      apiVerifyEmailRequest(email)
        .then(json => {
          let found = Boolean(json);
          setFeedback(found);
          if (found) {
            apiNewPasswordRequest(email).then(() => {
              setIsFetching(false);
            });
          } else {
            setEmailFound(false);
            setIsFetching(false);
          }
        })
        .catch(() => setIsFetching(false));
    }
  };

  const generateFeedback = () => {
    if (emailFound === false) {
      return "Este usuário não existe";
    }
  };

  return (
    <section className="PasswordRecovery">
      <form className={!feedback ? "visibleForm" : ""} onSubmit={handleSubmit}>
        <div>
          <h4>
            Informe seu e-mail e enviaremos instruções para sua nova senha.
          </h4>
          <Input
            label={"Digite seu email:"}
            value={email}
            valid={validEmail}
            placeholder={"email@provedor.com"}
            displayError={displayError}
            updateState={setEmail}
            required
            type={"email"}
            errorMessage={"Email inválido"}
          />
        </div>
        <div>
          <p>{generateFeedback()}</p>
          <button>Envie-me instruções</button>
        </div>
      </form>
      <div className={` message ${feedback ? "visible" : ""}`}>
        <h4>Olhe seu e-mail.</h4>
        <p>
          Nós enviamos instruções por e-mail para que você configure sua nova
          senha.
        </p>
        <p>
          Caso não receba, certifique-se de que o e-mail de registro é o correto
          e confira na caixa de spam.
        </p>
        <p>Qualquer dúvida entre em contato conosco. </p>
      </div>
      <Loading active={isFetching} />
    </section>
  );
};

export default PasswordRecovery;
