import { GET_COURSE, GET_COURSES, GET_COURSES_FAILED, GET_COURSES_SUCCEEDED, GET_COURSE_FAILED, GET_COURSE_SUCCEEDED } from "../constants/courses";

export const getCourses = () => ({
    type: GET_COURSES
});

export const getCoursesSucceeded = (data) => ({
  type: GET_COURSES_SUCCEEDED,
  data: data
});

export const getCoursesFailed = (error) => ({
  type: GET_COURSES_FAILED,
  error: error
}); 

export const getCourse = () => ({
    type: GET_COURSE
});

export const getCourseSucceeded = (data) => ({
  type: GET_COURSE_SUCCEEDED,
  data: data
});

export const getCourseFailed = (error) => ({
  type: GET_COURSE_FAILED,
  error: error
}); 
