import React from 'react';
import { replaceWithLineBreaks } from '../../../utils/formating';
import './B2BCourseCard.scss';
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg';
import { isMobile } from '../../../utils/responsiveness';
import { areaEnumTranslator, categoryEnumTranslator } from '../../../utils/enumTranslator';

const B2BCourseCard = ({ courseData, onClick }) => {
  return (
    <div className='B2BCourseCard' role={isMobile() && 'button'} onClick={isMobile() && onClick}>
      <p>{categoryEnumTranslator(courseData?.category)} em {areaEnumTranslator(courseData?.area)}</p>
      <h2 dangerouslySetInnerHTML={{__html: replaceWithLineBreaks(courseData?.name)}}></h2>
      <img src={courseData?.cardImage?.url} alt={courseData?.cardImage?.alt} loading="lazy"/>
      <p>{courseData?.cardDescription}</p>
      <button onClick={onClick}>SAIBA MAIS <Arrow/></button>
    </div>
    )
  }
  
export default B2BCourseCard;
