import React, { useEffect } from 'react';
import B2CCourseCard from '../../components/common/B2CCourseCard/B2CCourseCard';
import './ForPeople.scss';
import { useNavigate } from 'react-router-dom';
import RoundText from '../../components/common/RotatingText/RotatingText';
import { useDispatch, useSelector } from 'react-redux';
import { listCourses } from '../../redux/actions/courses';

const ForPeople = ({ inverted }) => {
  const b2cCourses = useSelector(state => state.courses.list);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => { 
    dispatch(listCourses({type: "people"}))
  }, [])

  return (
    <main className={`ForPeople ${inverted ? "inverted" : ""}`}>
    <RoundText inverted/>
    {
      b2cCourses.items?.map((course, index) =>
      <B2CCourseCard key={index} courseData={course} onClick={ () => navigate(`/para-pessoas/cursos/${course.id}`) }></B2CCourseCard>)
    }
    </main>
    )
  }
  
export default ForPeople;
