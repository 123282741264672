import React from "react";
import "./PrivacyPolicy.scss";
import {Link} from "react-router-dom";

const PrivacyPolicy = () => (
  <section className="PrivacyPolicy">
    <h2>POLÍTICA DE PRIVACIDADE</h2>
    <div>
      <div>
        <h3>POLÍTICA DE PRIVACIDADE E CONFIDENCIALIDADE DA INFORMAÇÃO</h3>
        <h3>MASTERTECH SERVIÇOS DE TREINAMENTO PROFISSIONAL LTDA
          (A EMPRESA)</h3>
        <p>
          A EMPRESA, no oferecimento de seus serviços, trata, coleta, armazena e, em alguns casos,
          revela informações sobre pessoas cadastradas/parceiros e visitantes (“Usuários”) de sites
          e/ou aplicativos móveis (web sites, dispositivos, produtos, serviços, lojas e aplicativos, etc),
          conforme detalhado aqui (“Plataformas” e “Serviços”, respectivamente).
        </p>
      </div>
      <div>
        <p>
          Este documento integra os Termos e Condições Gerais de Uso do Site. Faz-se necessário
          que o Usuário dê seu consentimento voluntário, expresso e informado a esta Declaração de
          Privacidade, tratando-se de um requisito essencial para que possa contratar e/ou
          estabelecer qualquer tipo de relacionamento com a EMPRESA.
        </p>
      </div>
      <div>
        <p>
          Esta Declaração de Privacidade se aplica a todos os serviços e operações de processamento
          de dados da EMPRESA, incluindo suas eventuais empresas subsidiárias ou afiliadas,
          constituindo-se a EMPRESA a responsável e controladora do processamento dos dados
          dos Usuários de suas Plataformas através de suas subsidiárias ou afiliadas.
        </p>
      </div>
      <div>
        <p>
          A EMPRESA coleta informações pessoais de seus Usuários para que esse possa usufruir
          dos serviços da empresa da melhor maneira possível. Tais informações podem ser: a)
          fornecidas pelo Usuário (ao registrar-se ou fornecer informações ao utilizar algum dos
          serviços); b) coletadas de forma automática (quando se navega/interage nas páginas,
          utilizam-se os serviços, etc – são utilizados cookies e outros identificadores únicos,
          coletando-se certos tipos de informações quando o navegador/dispositivo acessa os
          serviços da empresa); e c) coletadas por meio de outras fontes confiáveis (informações
          oriundas de transportadoras/correio sobre entrega, por exemplo).
        </p>
      </div>
      <div>
        <h3>A EMPRESA coleta as Informações Pessoais para os seguintes fins:</h3>
        <p>
          <span>I.</span> Fornecer os produtos, serviços e/ou benefícios oferecidos em seu site e
          eventualmente em suas outras plataformas.
        </p>
        <p>
          <span>II.</span> Receber e organizar os pedidos, entregar produtos e serviços, processar
          pagamentos e fornecer informações sobre pedidos, produtos, serviços e ofertas
          promocionais.
        </p>
        <p>
          <span>III.</span> Identificação, registro em sistemas e contato com o usuário, em conformidade com
          os requisitos legais.
        </p>
        <p>
          <span>IV.</span> Fornecer os produtos, serviços e/ou benefícios que o usuário solicitar ou contratar
          com a plataforma.
        </p>
        <p>
          <span>V.</span> Elaborar e manter um registro das operações realizadas, bem como informar o
          usuário sobre elas e realizar o acompanhamento adequado.
        </p>
        <p>
          <span>VI.</span> Fornecer suporte e atender a comentários, reclamações e sugestões por parte dos
          usuários.
        </p>
        <p>
          <span>VII.</span> Corrigir erros e melhorar a usabilidade e a efetividade dos serviços
        </p>
        <p>
          <span>VIII.</span> Cobrança judicial e/ou extrajudicial.
        </p>
        <p>
          <span>IX.</span> Oferecer os serviços e funcionalidades que melhor atendam às necessidades dos
          usuários e personalizar os serviços e a experiência no uso do site.
        </p>
        <p>
          <span>X.</span> Contribuir para a segurança das relações, comunicações e transações entre os
          usuários da plataforma.
        </p>
        <p>
          <span>XII.</span> Desenvolver estudos internos sobre interesses e comportamentos, para oferecer
          melhores serviços e produtos, além de realizar estudos estatísticos.
        </p>
        <p>
          <span>XIII.</span> Traçar perfis analisando diversas variáveis, como conduta ou interações na
          plataforma, análise e previsão de capacidade econômica, preferências, interesses,
          histórico de transações, comportamento e localização, entre outras, para obter
          melhora nas iniciativas comerciais e promocionais da plataforma, exibir publicidade
          ou promoções, banners de interesse, notícias, aprimorar a oferta de conteúdo e
          artigos, personalizar o conteúdo, apresentação e serviços.
        </p>
        <p>
          <span>XIV.</span> Oferecer os serviços e funcionalidades que melhor atendam às necessidades dos
          usuários e personalizar os serviços para tornar a experiência entre usuário e
          plataforma o mais confortável possível.
        </p>
        <p>
          <span>XV.</span> Aprimorar as iniciativas comerciais e promocionais e analisar as páginas visitadas, as
          pesquisas realizadas pelos usuários, para aprimorar a oferta de conteúdo e artigos,
          personalizar o conteúdo, sua apresentação e serviços.
        </p>
        <p>
          <span>XVI.</span> Fornecer informações através de diferentes canais (por e-mail, mensagens de texto
          (SMS), mensagens push, ligações telefônicas ou qualquer outro meio de
          comunicação) sobre melhorias ou novas funções ou serviços da plataforma.
        </p>
        <p>
          <span>XVII.</span> Cumprir as obrigações e requisitos legais das autoridades competentes.
        </p>
        <p>
          <span>XVIII.</span> Detectar e prevenir fraudes, abusos e crimes relacionados a fim de proteger a
          segurança dos usuários e da plataforma, mantendo a sustentabilidade dessa.
        </p>
        <p>
          <span>XIX.</span> Reivindicar os direitos da EMPRESA em caso de violação de seus Termos e
          Condições.
        </p>
        <p>
          <span>XX.</span> Permitir que outros usuários ou terceiros reivindiquem seus direitos.
        </p>
        <p>
          <span>XVIII.</span> Detectar e prevenir fraudes, abusos e crimes relacionados a fim de proteger a
          segurança dos usuários e da plataforma, mantendo a sustentabilidade dessa.
        </p>
        <p>
          <span>XXI.</span> Colaborar com os proprietários de propriedade intelectual no exercício desses
          direitos, sejam eles próprios ou de terceiros, bem como colaborar com eventuais
          investigações de violação de tais direitos.
        </p>
        <p>
          <span>XXII.</span> Fazer anúncios e contatos publicitários e promocionais, incluindo contato com
          usuários por meio de diferentes canais (e-mail, mensagens de texto (SMS),
          mensagens push, chamadas telefônicas ou outros meios de comunicação) para fins
          publicitários e/ou promocionais tanto de produtos como serviços da EMPRESA
          e/ou de terceiros.
        </p>
        <p>
          <span>XXIII.</span> Realizar qualquer tipo de atividade de marketing, publicidade, prospecção comercial
          e/ou pesquisa de mercado.
        </p>
        <p>
          <span>XXIV.</span> Uso para outra finalidade específica que será comunicada ao usuário, dependendo
          de consentimento desse, que poderá, nesse caso, por ele ser revogado
          posteriormente e a qualquer momento.
        </p>
      </div>
      <div>
        <h3>A EMPRESA não vende ou de qualquer forma comercializa informações que identifiquem
          seus usuários, bem como jamais transfere ou compartilha tais informações para terceiros,
          exceto conforme indicado abaixo:</h3>
        <p>
          A EMPRESA poderá ceder, transmitir e/ou transferir as Informações Pessoais dos
          Usuários nesses casos:
        </p>
        <p>
          <span>a.</span> Transações envolvendo terceiros: serviços, produtos, aplicativos ou funcionalidades
          e tecnologias fornecidos por terceiros para uso nos ou através dos serviços da
          EMPRESA, bem como relacionamento com empresas com atividades financeiras
          ou comerciais com as quais é mantido relacionamento de colaboração ou parceria, a
          fim de oferecer promoções, produtos e serviços dessas empresas ou co-branding.
          Aplica-se também a empresas que prestam ou possam prestar serviços meio à
          EMPRESA, como atendimento, logística, provedores, entrega, transporte,
          comunicação e marketing, análise e segurança de dados, resolução de disputas,
          processamento de pagamentos entre outros.
        </p>
        <p>
          <span>b.</span> Empresas do mesmo grupo: empresas pertencentes ao mesmo grupo econômico da
          EMPRESA, que operam sob os mesmos processos e políticas internas, sejam elas
          controladas, controladoras ou afiliadas à primeira.
        </p>
        <p>
          <span>c.</span> Proteção e segurança: para fins de cumprimento das leis; de execução ou aplicação
          dos Termos de Uso da Plataforma e outros protocolos e acordos; ou de proteção
          dos direitos, bens e segurança da EMPRESA, de seus usuários ou de terceiros,
          incluindo troca de informações com órgãos públicos e outras empresas e
          organizações para proteção de fraude, proteção, segurança e redução de riscos.
          Podem ser fornecidas informações para as autoridades públicas competentes,
          mesmo que não haja ordem ou citação executiva ou judicial para esse efeito, para os
          seguintes fins: (a) colaborar na investigação e denunciar fraudes, pirataria, violação
          de direitos de propriedade intelectual ou qualquer outro ato ilícito, bem como
          qualquer atividade ou circunstância que possa gerar responsabilidade legal para os
          usuários e/ou para a EMPRESA; (b) resguardar um interesse público, a aplicação
          ou administração da justiça, o reconhecimento, exercício ou defesa de um direito
          em um processo judicial ou administrativo e/ou a resolução de disputas; e (c)
          cumprir com qualquer lei, regulamento ou disposição legal aplicável, ou algum
          mandato de autoridade competente devidamente fundamentado e motivado.
        </p>
        <p>
          <span>d.</span> Usuários e/ou visitantes em geral: outros usuários e/ou visitantes do Site, a fim de
          fornecer um serviço de qualidade ao consumidor final, fornecendo informações
          para que esses possam tomar uma decisão de compra informada e adequada a seus
          interesses.
        </p>
        <p>
          Da mesma forma, ao aceitar estes termos, o Usuário dá seu consentimento expresso e
          informado para que a EMPRESA, caso necessário, ceda, transmita ou transfira as
          Informações Pessoais aos destinatários descritos nesta Declaração de Privacidade.
        </p>
        <p>
          Caso seja necessário, a EMPRESA poderá compartilhar as Informações Pessoais do
          Usuário com terceiros que não os aqui mencionados solicitando consentimento prévio e
          expresso, desde que não haja autorização ou obrigação legal que permita que seja realizado
          sem tal consentimento.
        </p>
        <p>
          As Informações Pessoais dos usuários serão mantidas pela EMPRESA pelo tempo que for
          necessário para cumprir com os fins para os quais foram coletadas, bem como para
          cumprir requisitos legais ou regulamentares ou durante o prazo de prescrição legal de
          possíveis responsabilidades legais e/ou contratuais, sendo, após o término do período, os
          dados excluídos ou anonimizados de forma que nenhuma pessoa possa ser individualizada,
          conforme permitido pela regulamentação de cada país.
        </p>
        <p>
          Pode haver decisões automatizadas dos dados e informações dos Usuários, sendo aquelas
          tomadas com base no uso de algoritmos e programas de computador, sem que nenhum ser
          humano esteja envolvido no processo de tomada de decisão. Tais decisões podem ser
          objeto de revisão e análise humana caso necessário.
        </p>
        <p>
          Para que possa haver reconhecimento do navegador ou dispositivo utilizado e
          oferecimento e melhoria dos serviços, a EMPRESA utiliza cookies e outros identificadores. <span>
          Nos sites e sistemas da empresa sob o domínio <Link to="/">(mastertech.com.br)</Link>  são utilizados
            rastreadores como doubleclick.net, facebook.com, facebook.net, google-analytics.com,
            googletagmanager.com e hotjar.com. Já no Blog e outros que utilizam a plataforma
            wordpress utiliza-se o rastreador doubleclick.net.
          </span>
        </p>
        <p>
          Caso haja alguma dúvida ou objeção sobre a coleta e o tratamento das informações
          pessoais, o Usuário deve entrar em contato com a EMPRESA pelos meios indicados na
          plataforma. Além disso, observando-se a lei aplicável, o Usuário possui o direito de pedir
          acesso, corrigir e excluir seus dados pessoais e pedir a portabilidade de dados, também
          podendo contestar o tratamento de seus dados pessoais pela plataforma ou pedir que haja
          restrição a esse tratamento em alguns casos.
        </p>
        <p>
          É importante que o Usuário atente para o escopo de sua responsabilidade e se proteja
          contra acessos não autorizados, protegendo seu login e senha e seus computadores,
          dispositivos e aplicativos, certificando-se, além de não divulgar tais dados de acesso a
          terceiros, de encerrar a sessão sempre que terminar de usar um computador compartilhado.
          O usuário será responsável por todos os atos que ocorrerem através do uso de seu login e
          senha, devendo modificá-los assim que houver suspeita de uso indevido por terceiros.
        </p>
        <p>
          A EMPRESA não será responsável pelo uso indevido das Informações Pessoais de
          Usuários por parte de terceiros se tais terceiros tiverem coletado e/ou processado
          diretamente tais informações.
        </p>
        <p>
          São proibidas condutas consideradas como “spam”, bem como o envio indiscriminado de
          mensagens de qualquer natureza entre os usuários da plataforma.
        </p>
        <p>
          A EMPRESA não é responsável pelo uso que qualquer usuário ou outra pessoa possa fazer
          das informações publicadas em seu site e plataformas. Os usuários concordam que a
          empresa não será responsável por perdas ou danos que possam ser gerados como resultado
          de negociações entre os usuários.
        </p>
        <p>
          A EMPRESA está em conformidade com os regulamentos e padrões do setor em relação
          às medidas de segurança aplicáveis às Informações Pessoais dos Usuários e não se
          responsabiliza por interceptações ilegais ou violação de seus sistemas ou bancos de dados,
          ou pela utilização por pessoas não autorizadas. Também não se responsabiliza pelo uso
          inadequado das informações obtidas por esses meios.
        </p>
        <p>
          Caso haja alteração nos presentes Termos na forma como as Informações Pessoais são
          gerenciadas, haverá notificação ao Usuário através dos canais habituais de comunicação,
          como e-mail ou mensagens através de aplicativos, sendo que a ausência de interação do
          usuário será considerada como aceite da nova versão da política.
        </p>
        <p>
          A presente Política de Privacidade e Confidencialidade da Informação é regida pelas leis
          aplicáveis na presente data. No caso de qualquer controvérsia ou divergência relacionada à
          interpretação, validade, celebração ou conformidade destes Termos, ela será resolvida pelos
          foros legais competentes a depender da matéria.
        </p>
      </div>



      <div>
        <h3>DADOS SENSÍVEIS</h3>

        <p>
          1) Em consonância com a Lei Geral de Proteção de Dados (LGPD) e entendendo a natureza delicada dos dados sensíveis, a MASTERTECH, ao lado das empresas parceiras, assume os seguintes compromissos:
        </p>
        <p>
          <span>(i) Definição de Dados Sensíveis:</span> Em conformidade com a LGPD, consideram-se dados sensíveis aqueles referentes à origem racial ou étnica, convicções religiosas, opiniões políticas, saúde ou vida sexual, entre outros. Neste contexto, a plataforma da MASTERTECH classifica como sensíveis informações como identidade de gênero, existência de deficiência, orientação sexual, entre outros.
        </p>
        <p>
          <span>(ii) Responsabilidade das Empresas Parceiras:</span> A Mastertech e suas empresas parceiras se comprometem a garantir a confidencialidade dos dados sensíveis aos quais tiverem acesso. Será responsabilidade destas empresas obter, quando necessário, o consentimento expresso do titular para o uso específico de tais dados.
        </p>
        <p>
          <span>(iii) Segregação e Acesso:</span> Os dados sensíveis serão armazenados de forma segregada. O acesso será restrito e as empresas parceiras devem assegurar que apenas indivíduos autorizados, com real necessidade, possam visualizar ou processar esses dados.
        </p>
        <p>
          <span>(iv) Medidas de Segurança Robustas:</span> Tanto a MASTERTECH quanto as empresas parceiras se comprometem a implementar e manter medidas de segurança rigorosas para proteção de dados sensíveis. Tais medidas incluirão criptografia avançada, sistemas de autenticação robustos, entre outros.
        </p>
        <p>
          <span>(v) Consentimento Explicitado:</span> A coleta, armazenamento e tratamento de dados sensíveis por parte da Mastertech e das empresas parceiras requererão um consentimento claramente destacado do titular, que poderá ser revogado a qualquer momento.
        </p>
        <p>
          <span>(vi) Limitação do Tratamento:</span> A Mastertech e as empresas parceiras garantem que o tratamento de dados sensíveis estará estritamente ligado às finalidades previamente informadas ao titular e consentidas por este. Não serão utilizados para fins divergentes do acordado.
        </p>
        <p>
          <span>(vii) Transparência:</span> Ambas as partes, MASTERTECH e empresas parceiras, garantirão o direito de acesso do titular aos seus dados sensíveis, assim como o direito de corrigir, atualizar ou solicitar sua exclusão.
        </p>
        <p>
          <span>(viii) Notificação de Violações:</span> Qualquer violação de segurança que possa comprometer dados sensíveis será prontamente comunicada ao titular e aos órgãos competentes. As empresas parceiras também se comprometem a informar a MASTERTECH imediatamente sobre qualquer incidente.
        </p>
        <p>
          <span>(ix) Auditorias e Compliance:</span> A MASTERTECH, sempre que necessário, conduzirá auditorias periódicas, podendo fazê-lo por entidades terceirizadas para garantir a conformidade com esta cláusula e a LGPD.
        </p>
      </div>
    </div>
  </section>
);

export default PrivacyPolicy;
