import { processResponse, msEndpoint, urlFilters } from "./common";

export const apiGetCourses = filters =>
  fetch(urlFilters(`${msEndpoint}/course/courses`, filters), {
    method: "GET"
}).then(processResponse);

export const apiGetCourse = id =>
  fetch(`${msEndpoint}/course/course/${id}`)
    .then(processResponse);
