import React, { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import TagManager from "react-gtm-module";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import {courses} from "./redux/reducers/courses";
import {product} from "./redux/reducers/product";

const reducers = combineReducers({courses, product});

const middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const store = createStore(reducers, applyMiddleware(...middleware));

const tagManagerArgs = {
  gtmId: "GTM-TW779GH"
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>);

serviceWorker.unregister();
