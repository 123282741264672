import { GET_PRODUCT, GET_PRODUCT_FAILED, GET_PRODUCT_SUCCEEDED} from "../constants/product";

export const getProduct = () => ({
    type: GET_PRODUCT
});

export const getProductSucceeded = (data) => ({
  type: GET_PRODUCT_SUCCEEDED,
  data: data
});

export const getProductFailed = (error) => ({
  type: GET_PRODUCT_FAILED,
  error: error
});
